const onboardingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STEP':
      return {
        ...state,
        step: action.payload,
      };
    case 'SET_PROFILE_DATA':
      return {
        ...state,
        profileData: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_COMPANY':
      return {
        ...state,
        companyData: action.payload,
      };
    case 'SET_INPUTTED_DATA':
      return {
        ...state,
        inputtedData: {
          ...state.inputtedData,
          ...action.payload,
        },
      };
    case 'SET_DOCUMENTS_PPH':
      return {
        ...state,
        documentsProfileData: action.payload,
      };
    case 'SET_DOCUMENTS_COMPANY':
      return {
        ...state,
        documentsCompanyData: action.payload,
      };
    case 'SET_PICTURES_COMPANY':
      return {
        ...state,
        picturesCompanyData: action.payload,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
