import { omitBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function useFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    const obj = { };
    searchParams.forEach((value, key) => {
      if (obj[key] != null) {
        obj[key] = Array.from([obj[key], value]).flat();
      } else {
        obj[key] = [value];
      }
    });
    return obj;
  }, [searchParams]);

  const handleChangeFilter = useCallback((name, value) => {
    setSearchParams({
      ...filters,
      [name]: value,
    });
  }, [filters, setSearchParams, searchParams]);

  const handleResetFilters = (nextState = {}) => {
    const cleanState = { ...nextState };
    Object.entries(nextState).forEach(([key, value]) => {
      if (value instanceof Date) {
        cleanState[key] = value.toISOString();
      }
    });
    setSearchParams(omitBy(cleanState, (v) => v == null || v?.[0] === ''));
  };

  return {
    filters,
    setFilters: handleChangeFilter,
    resetFilters: handleResetFilters,
  };
}

export default useFilters;
