import { utils } from 'ui-library-unlocker';
import { getDocumentDownloadUrl } from '../services/document';

export const handleDocumentDownload = async (document, t = undefined) => {
  try {
    const { data: { presignedUrl } } = await getDocumentDownloadUrl(document?.uid);
    if (presignedUrl) {
      window.open(presignedUrl, '_blank');
    }
  } catch (error) {
    switch (error?.response?.status) {
      case 404:
        if (t) utils.toast.error(t(`global.form.errors.file.${error?.response?.data?.message}`));
        else utils.toast.error('DOCUMENT_NOT_FOUND_ERROR');
        break;
      default:
        break;
    }
  }
};

export const handleFileSelect = (e, setHugeDocument, formik, setFile, t) => {
  const f = e?.target?.files[0];

  if (f?.size > 10000000) {
    utils.toast.error(t('global.form.errors.file.tooLarge'));
    setHugeDocument(true);
    return;
  }

  if (f) {
    const setFileFields = (image = null) => {
      const dim = image
        ? {
          width: image.naturalWidth,
          height: image.naturalHeight,
        }
        : {};

      formik.setFieldValue('filename', f?.name);
      formik.setFieldValue('metadata', {
        documentSize: f?.size,
        extension: f?.type?.split('/')[1],
        ...dim,
      });
      setFile(f);

      if (image) image.removeEventListener('load', setFileFields);
    };

    if (f?.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onload = () => setFileFields(null);
      reader.readAsText(f);
    } else {
      const image = new Image();
      image.src = URL.createObjectURL(f);
      image.addEventListener('load', () => setFileFields(image));
    }
  } else {
    setFile(null);
    formik.setFieldValue('filename', '');
    formik.setFieldValue('metadata', null);
  }
};
