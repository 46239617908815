import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

// Utils
import cn from '../../../utils/cn';

import styles from './Button.module.scss';

function Button({
  label,
  type,
  icon,
  iconSide,
  variant,
  size,
  blockLevel,
  className,
  children,
  loading,
  ...btnProps
}) {
  const isIconOnly = (!label && !children) && icon;
  const isContentOnly = !icon && !loading;

  return (
    <button
      {...btnProps}
      type={type}
      className={cn([
        styles.btn,
        styles[variant],
        loading ? styles.loading : null,
        size ? styles[size] : null,
        blockLevel ? styles.blockLevel : null,
        className,
        isIconOnly ? styles.iconOnly : null,
        isContentOnly ? styles.contentOnly : null,
      ])}
    >
      <div className={cn([styles.content, iconSide === 'left' ? styles.left : styles.right])}>
        <div className={styles.icon}>
          <Picto
            width={size === 'small' ? 16 : 20}
            icon={loading ? 'loading' : icon}
          />
        </div>
        <div className={styles.label}>
          {children || label}
        </div>
      </div>
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.string,
  iconSide: PropTypes.oneOf(['left', 'right']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'default-outline', 'plain', 'grey']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  blockLevel: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  label: null,
  type: 'button',
  icon: null,
  iconSide: 'right',
  variant: 'primary',
  size: 'medium',
  blockLevel: false,
  className: null,
  children: null,
  loading: false,
};

export default Button;
