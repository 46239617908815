import React from 'react';
import { useFormik } from 'formik';
import {
  TextInput,
  TextAreaInput,
  Button,
} from 'ui-library-unlocker';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Modal from '../../components/molecules/Modal/Modal';
import { hideModal } from '../../utils/modal';
import styles from './Support.module.scss';
import newFeedBackSchema, { newFeedBackInitialValues } from '../../utils/forms/newFeedBackSchema';

function NewFeedbackModal({ id }) {
  const send = (values) => {
    Sentry.sendFeedback(
      {
        name: values.name, // optional
        email: values.email, // optional
        message: values.description, // required
      },
      {
        includeReplay: true, // optional
        minReplayDuration: 15000,
        networkDetailAllowUrls: [
          window.location.origin,
          'unlocker-api.traefik.me',
          'api-staging.unlocker.dev',
          'api-production.unlocker.dev',
        ],
        maskAllText: false,
        maskAllInputs: false,
      },
    ).then(() => hideModal(id));
  };

  const formik = useFormik({
    initialValues: newFeedBackInitialValues,
    validationSchema: newFeedBackSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      send(values);
    },
  });

  return (
    <Modal
      id={id}
      title={t('support.form.title')}
      size="large"
      onClose={() => {
        hideModal(id);
      }}
    >
      <form onSubmit={formik.handleSubmit} className="text-left">
        <TextInput
          id="name"
          name="name"
          type="text"
          className="m-t-25"
          label={t('support.form.name')}
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        <TextInput
          id="email"
          name="email"
          className="m-t-25"
          label={t('support.form.email')}
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <TextAreaInput
          id="description"
          className="m-t-25"
          name="description"
          label={t('support.form.description')}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        <div className={styles.centerButton}>
          <Button
            type="submit"
            variant="primary"
            className="m-t-20"
            size="large"
            label={t('support.form.submit')}
            loading={formik.isSubmitting}
          />
        </div>
      </form>
    </Modal>
  );
}
NewFeedbackModal.propTypes = {
  id: PropTypes.string.isRequired,
};
export default NewFeedbackModal;
