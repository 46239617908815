/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useMemo } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

// Components
import {
  utils,
  UnlockerLoader,
} from 'ui-library-unlocker';
import ImageUpload from '../../../components/organisms/ImageUpload/ImageUpload';

// Services
import {
  getCompanyPictures,
} from '../../../services/company';

// Hooks
import { useAppContext } from '../../../store/context';

import styles from './CompanyComplementary.module.scss';

function CompanyComplementary() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();

  const match = useMatch('/company/:id');

  const {
    data: picturesListData,
    isFetching: picturesListFetching,
    refetch: refetchPicturesList,
  } = useQuery({
    queryKey: ['company-pictures', user],
    queryFn: () => getCompanyPictures(match?.params?.id),
    enabled: !!user,
    keepPreviousData: true,
  });

  const companyLogo = useMemo(() => (
    picturesListData?.data?.collection?.[0]
  ), [picturesListData]);

  if (!uiBuilders || picturesListFetching) return <UnlockerLoader size={300} />;

  return (
    <div className={utils.cn(['m-t-50', styles.companyComplementary])}>
      <h2 className="m-b-20">{t('company.crud.logoTitle')}</h2>
      <ImageUpload
        entityUID={match?.params?.id}
        entityType="company"
        picture={companyLogo}
        refetchPicture={refetchPicturesList}
        fileType="logo"
      />
    </div>
  );
}

CompanyComplementary.propTypes = {
};

export default CompanyComplementary;
