/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useMemo, useCallback, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import {
  utils,
  TextInput,
  DateInput,
  SelectInput,
  RadioGroup,
  Button,
} from 'ui-library-unlocker';

// Components
import AddressInput from '../../../molecules/AddressInput/AddressInput';
import ValidationChangeModal from '../../../molecules/ValidationChangeModal/ValidationChangeModal';
import IbanInput from '../../../molecules/IbanInput/IbanInput';

// Context
import { useAppContext } from '../../../../store/context';

// Hooks
import useScroll from '../../../../hooks/useScroll';

// Services
import { createCompany, updateCompany } from '../../../../services/company';

// Utils
import {
  companyDetailsSchema,
  companyDetailsInitialValues,
  legalCategories,
} from '../../../../utils/forms/companyDetailsSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { showModal } from '../../../../utils/modal';

// Constants
import { COUNTRIES } from '../../../../utils/countries';
import { ENROLMENT_STATUS } from '../../../../utils/constants';

import styles from './CompanyForm.module.scss';

const COMPANY_CHANGE_MODAL_ID = 'COMPANY_CHANGE_MODAL';

function CompanyForm({
  isEditing,
  companyQuery,
  isReadOnly,
}) {
  const { t } = useTranslation();
  const { context: { user, hasRealEstateAgency }, refetchMe } = useAppContext();
  const navigate = useNavigate();
  const { scrollContentToTop } = useScroll();
  const ibanRef = useRef(null);

  const match = useMatch('/company/:id');

  const {
    data: companyData,
    error: companyError,
    isFetched: companyIsFetched,
    refetch: companyRefetch,
  } = companyQuery || {};

  const initialValues = useMemo(() => {
    if (companyError) return companyData;
    if (companyData) {
      return companyData?.data;
    }
    return companyDetailsInitialValues;
  }, [user, companyData]);

  const formik = useFormik({
    initialValues,
    validationSchema: companyDetailsSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!isReadOnly) {
        if (companyData?.data?.onboardingStatus !== ENROLMENT_STATUS.PENDING
          && companyData?.data?.onboardingStatus !== ENROLMENT_STATUS.PROFILE_DONE
          && isEditing
        ) {
          showModal(COMPANY_CHANGE_MODAL_ID);
          formik.setSubmitting(false);
          return;
        }
        if (!values?.isRealEstateAgency) {
          delete values.rcsRegisterLocation;
          delete values.proCardNumber;
          delete values.warrantyFund;
          delete values.proCivilLiability;
        }
        companyMutation.mutate(values);
      }
    },
  });

  useEffect(() => {
    if (hasRealEstateAgency) {
      formik.setFieldValue('isRealEstateAgency', false);
    }
  }, [hasRealEstateAgency]);

  const companyMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        return updateCompany(match?.params?.id, data);
      }
      return createCompany(data);
    },
    onSuccess: ({ response, status, data }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          refetchMe();
          utils.toast.success(t('company.crud.addSuccess'));
          navigate(`/company/${data?.uid}#documents`);
          break;
        case 204:
          refetchMe();
          companyRefetch().then(() => {
            utils.toast.success(t('company.crud.editSuccess'));
            ibanRef.current?.resetEditMode();
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err.response.status) {
          case 400: {
            if (err.response.data?.message === 'PERSON_PERSON_ROLE_EXCEPTION') {
              utils.toast.error(t(`company.crud.formErrors.${err.response.data?.message}`));
            } else {
              utils.toast.error(t('global.form.errors.generic'));
            }
            formik.setErrors(err.response.data?.errors);
            break;
          }
          case 409: {
            if (
              err.response.data?.message === 'PERSON_COMPANY_CONFLICT'
              || err.response.data?.message === 'PERSON_COMPANY_REAL_ESTATE_CONFLICT'
            ) {
              utils.toast.error(t(`company.crud.formErrors.${err.response.data?.message}`));
            } else {
              utils.toast.error(t('global.form.errors.generic'));
            }
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const companyTypeOptions = useMemo(() => {
    const options = [
      {
        id: 'normal',
        label: t('company.crud.form.companyType.normal'),
        value: 'normal',
      },
    ];
    if (!hasRealEstateAgency) {
      options.push({
        id: 'realEstate',
        label: t('company.crud.form.companyType.realEstate'),
        value: 'realEstate',
      });
    }
    return options;
  }, [t, hasRealEstateAgency]);

  const handleCompanyTypeChange = useCallback((value) => {
    formik.setFieldValue('isRealEstateAgency', value === 'realEstate');
  }, [formik]);

  const countryOptions = useMemo(() => Object.keys(COUNTRIES).map((countryCode) => ({
    value: countryCode,
    label: COUNTRIES[countryCode],
  })), [t]);

  const legalCategoryOptions = useMemo(() => legalCategories.map((category) => ({
    value: category.value,
    label: category.label,
  })), [t]);

  const registrationDateValue = useMemo(() => {
    try {
      if (!formik.values.registrationDate) return null;
      const date = new Date(formik.values.registrationDate);
      return date;
    } catch {
      return null;
    }
  }, [formik.values.registrationDate]);

  if (!companyIsFetched) return <div className={styles.wrapper}>{t('global.loading')}</div>;

  return (
    <div className={styles.wrapper}>
      <div>
        <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
          <h3>{t('company.crud.formSections.identification')}</h3>
          {isEditing ? (
            <p className="m-t-25">
              {t(`company.crud.form.companyType.${formik.values.isRealEstateAgency ? 'realEstate' : 'normal'}`)}
            </p>
          ) : (
            <>
              <RadioGroup
                name="companyType"
                className="m-t-25"
                options={companyTypeOptions}
                value={formik.values.isRealEstateAgency ? 'realEstate' : 'normal'}
                onChange={handleCompanyTypeChange}
                disabled={isReadOnly || hasRealEstateAgency}
              />
              {hasRealEstateAgency && (
                <p className={utils.cn(['m-t-10', styles.companyTypeInfo])}>
                  {t('company.crud.form.companyType.info')}
                </p>
              )}
            </>
          )}

          {formik?.values?.unlockerId && (
          <TextInput
            className="m-t-25"
            type="text"
            id="unlockerId"
            name="unlockerId"
            label={t('company.crud.form.unlockerId')}
            value={formik?.values?.unlockerId}
            disabled
          />
          )}
          <TextInput
            type="text"
            id="legalName"
            className="m-t-25"
            name="legalName"
            label={t('company.crud.form.legalName')}
            error={displayError(t, formik, 'legalName')}
            valid={isFieldValid(formik, 'legalName', null, initialValues?.legalName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.legalName}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="brandName"
            name="brandName"
            className="m-t-25"
            label={t('company.crud.form.brandName')}
            error={displayError(t, formik, 'brandName')}
            valid={isFieldValid(formik, 'brandName', null, initialValues?.brandName)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.brandName}
            disabled={isReadOnly}
          />
          <DateInput
            id="registrationDate"
            name="registrationDate"
            className={utils.cn(['m-t-25'])}
            label={t('company.crud.form.registrationDate')}
            error={displayError(t, formik, 'registrationDate')}
            valid={isFieldValid(formik, 'registrationDate', null, initialValues?.registrationDate)}
            onChange={(date) => formik.setFieldValue('registrationDate', date)}
            info={t('lease.add.form.general.fields.startDateInfo')}
            onBlur={formik.handleBlur}
            value={registrationDateValue}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="registrationPlace"
            name="registrationPlace"
            className="m-t-15"
            label={t('company.crud.form.registrationPlace')}
            error={displayError(t, formik, 'registrationPlace')}
            valid={isFieldValid(formik, 'registrationPlace', null, initialValues?.registrationPlace)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.registrationPlace}
            disabled={isReadOnly}
          />
          <SelectInput
            id="registrationCountry"
            name="registrationCountry"
            className={utils.cn(['m-t-25', styles.registrationCountryInput])}
            label={t('company.crud.form.registrationCountry')}
            options={countryOptions}
            error={displayError(t, formik, 'registrationCountry')}
            valid={isFieldValid(formik, 'registrationCountry', null, initialValues?.registrationCountry)}
            onChange={(value) => {
              formik.setFieldValue('registrationCountry', value.value);
            }}
            onBlur={formik.handleBlur}
            // eslint-disable-next-line max-len
            value={countryOptions.find((registrationCountry) => registrationCountry.value === formik.values.registrationCountry)}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="idNumber"
            name="idNumber"
            className="m-t-25"
            label={t('company.crud.form.idNumber')}
            error={displayError(t, formik, 'idNumber')}
            valid={isFieldValid(formik, 'idNumber', null, initialValues?.idNumber)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.idNumber}
            disabled={isReadOnly}
          />
          {companyIsFetched && (
            <AddressInput
              id="address"
              name="address"
              className="m-t-25"
              label={t('company.crud.form.address')}
              error={displayError(t, formik, 'address')}
              valid={isFieldValid(formik, 'address', null, initialValues?.address)}
              onAddressSelect={(value) => formik.setFieldValue('address', value)}
              value={formik.values.address || null}
              disabled={isReadOnly}
            />
          )}
          {formik.values.isRealEstateAgency && (
            <>
              <TextInput
                type="text"
                id="rcsRegisterLocation"
                name="rcsRegisterLocation"
                className="m-t-25"
                label={t('company.crud.form.rcsRegisterLocation')}
                error={displayError(t, formik, 'rcsRegisterLocation')}
                valid={isFieldValid(formik, 'rcsRegisterLocation', null, initialValues?.rcsRegisterLocation)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rcsRegisterLocation}
                disabled={isReadOnly}
              />
              <TextInput
                type="text"
                id="proCardNumber"
                name="proCardNumber"
                className="m-t-25"
                label={t('company.crud.form.proCardNumber')}
                error={displayError(t, formik, 'proCardNumber')}
                valid={isFieldValid(formik, 'proCardNumber', null, initialValues?.proCardNumber)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.proCardNumber}
                disabled={isReadOnly}
              />
              <TextInput
                type="text"
                id="warrantyFund"
                name="warrantyFund"
                className="m-t-25"
                label={t('company.crud.form.warrantyFund')}
                error={displayError(t, formik, 'warrantyFund')}
                valid={isFieldValid(formik, 'warrantyFund', null, initialValues?.warrantyFund)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.warrantyFund}
                disabled={isReadOnly}
              />
              <TextInput
                type="text"
                id="proCivilLiability"
                name="proCivilLiability"
                className="m-t-25"
                label={t('company.crud.form.proCivilLiability')}
                error={displayError(t, formik, 'proCivilLiability')}
                valid={isFieldValid(formik, 'proCivilLiability', null, initialValues?.proCivilLiability)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.proCivilLiability}
                disabled={isReadOnly}
              />
            </>
          )}

          <h3 className="m-t-50">{t('company.crud.formSections.legalForm')}</h3>
          <SelectInput
            id="legalCategory"
            name="legalCategory"
            className={utils.cn(['m-t-25', styles.legalCategoryInput])}
            label={t('company.crud.form.legalForm')}
            options={legalCategoryOptions}
            error={displayError(t, formik, 'legalCategory')}
            valid={isFieldValid(formik, 'legalCategory', null, initialValues?.legalCategory)}
            onChange={(value) => {
              formik.setFieldValue('legalCategory', value.value);
            }}
            onBlur={formik.handleBlur}
            value={legalCategoryOptions.find((legalCategory) => legalCategory.value === formik.values.legalCategory)}
            disabled={isReadOnly}
          />
          <h3 className="m-t-50">{t('company.crud.formSections.bankingInfo')}</h3>
          <IbanInput
            id="iban"
            name="iban"
            className="m-t-25"
            label={t('company.crud.form.iban')}
            error={displayError(t, formik, 'iban')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.iban}
            disabled={isReadOnly}
            editMode={isEditing}
            ref={ibanRef}
          />

          <h3 className="m-t-50">{t('company.crud.formSections.fiscalData')}</h3>
          <TextInput
            type="text"
            id="annualTurnover"
            name="annualTurnover"
            className="m-t-25"
            label={t('company.crud.form.annualTurnover')}
            error={displayError(t, formik, 'annualTurnover')}
            valid={isFieldValid(formik, 'annualTurnover', null, initialValues?.annualTurnover)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.annualTurnover}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="vatNumber"
            name="vatNumber"
            className="m-t-25"
            label={t('company.crud.form.vatNumber')}
            error={displayError(t, formik, 'vatNumber')}
            valid={isFieldValid(formik, 'vatNumber', null, initialValues?.vatNumber)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vatNumber}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="taxZipCode"
            name="taxZipCode"
            className="m-t-25"
            label={t('company.crud.form.taxZipCode')}
            error={displayError(t, formik, 'taxZipCode')}
            valid={isFieldValid(formik, 'taxZipCode', null, initialValues?.taxZipCode)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taxZipCode}
            disabled={isReadOnly}
          />
          <TextInput
            type="text"
            id="taxCity"
            name="taxCity"
            className="m-t-25"
            label={t('company.crud.form.taxCity')}
            error={displayError(t, formik, 'taxCity')}
            valid={isFieldValid(formik, 'taxCity', null, initialValues?.taxCity)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taxCity}
            disabled={isReadOnly}
          />
          <SelectInput
            id="taxCountry"
            name="taxCountry"
            className={utils.cn(['m-t-25', styles.taxCountryInput])}
            label={t('company.crud.form.taxCountry')}
            options={countryOptions}
            error={displayError(t, formik, 'taxCountry')}
            valid={isFieldValid(formik, 'taxCountry', null, initialValues?.taxCountry)}
            onChange={(value) => {
              formik.setFieldValue('taxCountry', value.value);
            }}
            onBlur={formik.handleBlur}
            value={countryOptions.find((taxCountry) => taxCountry.value === formik.values.taxCountry)}
            disabled={isReadOnly}
          />
          {!isReadOnly && (
            <div className={styles.submit}>
              <Button
                type="submit"
                size="large"
                loading={formik.isSubmitting}
                label={t('global.validate')}
              />
              <p
                tabIndex={0}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                onKeyDown={null}
                className={styles.cancel}
                onClick={() => {
                  formik.resetForm();
                  if (isEditing) ibanRef.current?.resetEditMode();
                  utils.toast.info(t('company.crud.cancelMessage'));
                  scrollContentToTop();
                }}
              >
                {t('global.cancel')}
              </p>
            </div>
          )}
        </form>
      </div>
      <ValidationChangeModal
        id={COMPANY_CHANGE_MODAL_ID}
        onAccept={() => companyMutation.mutate(formik.values)}
        submitLoading={companyMutation.isLoading}
        entity="company"
      />
    </div>
  );
}

CompanyForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  companyQuery: PropTypes.shape().isRequired,
  isReadOnly: PropTypes.bool,
};

CompanyForm.defaultProps = {
  isReadOnly: false,
};

export default CompanyForm;
