import PropTypes from 'prop-types';
import { Picto, utils } from 'ui-library-unlocker';

import styles from './BackButton.module.scss';

function BackButton({
  className,
  label,
  onClick,
}) {
  return (
    <button
      className={utils.cn([styles.backButton, className])}
      type="button"
      onClick={onClick}
    >
      <Picto
        icon="arrow-down"
        width={24}
        className={styles.backArrow}
      />
      <span>{label}</span>
    </button>
  );
}

BackButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  className: null,
};

export default BackButton;
