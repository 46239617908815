import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

// Components
import {
  Button,
  Message,
  utils,
  UnlockerLoader,
} from 'ui-library-unlocker';
import Modal from '../../../components/molecules/Modal/Modal';
import ProfileDocumentsList from '../../../components/organisms/DocumentList/ProfileDocumentsList/ProfileDocumentsList';

// Hooks
import { useAppContext } from '../../../store/context';
import useProfile from '../../../hooks/useProfile';
// import useProfileCompletion from '../../../hooks/useProfileCompletion';

// Services
import { getPersonDocuments } from '../../../services/person';
import { requestBankingEnrolment } from '../../../services/profile';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { checkIfSendProfileValidationRequest } from '../../../utils/user';

// Constants
import { ENROLMENT_STATUS } from '../../../utils/constants';

import styles from './ProfileDocuments.module.scss';

const SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID = 'send-validation-request-confirm-modal';

function ProfileDocuments() {
  const { t } = useTranslation();
  const { context: { user, uiBuilders, me }, refetchMe } = useAppContext();
  const {
    isLoadingProfile,
  } = useProfile();
  // const { isUserCompletionLevel1 } = useProfileCompletion();
  const navigate = useNavigate();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const {
    data: documentListData,
    isLoading: documentListLoading,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['profile-documents', user],
    queryFn: () => getPersonDocuments(user?.username),
    enabled: !!user,
    keepPreviousData: true,
  });

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: 'profile',
        },
      });
      return requestBankingEnrolment();
    },
    onSuccess: () => {
      hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
      utils.toast.success(t('profile.paymentAccount.success'));
      refetchMe();
      navigate('/');
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));

            if (err?.response?.data?.message === 'PERSON_ENROLMENT_DOCUMENT_MISSING') {
              const { errors } = err?.response?.data || {};
              TagManager.dataLayer({
                dataLayer: {
                  event: 'incomplete_validation_request',
                  entityType: 'profile',
                },
              });
              if (errors) {
                const missing = Object.keys(errors).map((key) => errors[key]);
                setMissingDocuments(missing || null);
              }
            }
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
      }
    },
  });

  const getDocumentType = useCallback(() => {
    if (uiBuilders == null || isLoadingProfile) return {};
    return uiBuilders['/person/ui']?.documentTypePPH;
  }, [uiBuilders, isLoadingProfile]);

  const canSendValidationRequest = useMemo(() => {
    if (documentListData?.data?.collection?.length < 1) return false;
    if (!me?.onboardingStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
    ].includes(me?.onboardingStatus);
  }, [me?.onboardingStatus, documentListData?.data?.collection?.length]);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest();

  const sendValidationBtnLabel = useMemo(() => {
    switch (me?.onboardingStatus) {
      case ENROLMENT_STATUS.PROFILE_DONE:
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
        return t('profile.submit');
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
        return t('profile.submitted');
      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return t('profile.submitValidated');
      default:
        return t('profile.submit');
    }
  }, [me]);

  if (documentListLoading) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className="m-t-50">
      {/* { isUserCompletionLevel1 && (
        <Message
          content={t('profile.documents.kindlyUploadDocuments')}
          variant="info"
          className="m-b-20"
        />
      )} */}

      <h2>{t('profile.documents.title')}</h2>

      {mustSendValidationRequest && (
        <Message
          content={t('profile.documents.requiredDocumentsPhysical')}
          variant="info"
          className={utils.cn(['m-t-20', styles.requiredDocumentsPhysical])}
        />
      )}

      <ProfileDocumentsList
        documentsData={documentListData?.data?.collection || []}
        refetchDocuments={refetchDocumentList}
        userUID={user?.username || ''}
      />

      {missingDocuments?.length > 0 && (
        <Message
          className={utils.cn(['m-t-30', styles.missingBlock])}
          icon={null}
          variant="alert"
          content={(
            <>
              <p className="p-2-700 m-b-10">
                {t('profile.documents.missingDocuments')}
              </p>
              <ul className={styles.missingList}>
                {missingDocuments.map((item) => (
                  <li key={item}>
                    {getDocumentType()[item.toLowerCase()]}
                  </li>
                ))}
              </ul>
            </>
          )}
        />
      )}

      {mustSendValidationRequest && (
        <Button
          className="m-t-40 center-block"
          icon="etat-lieux"
          size="large"
          disabled={!canSendValidationRequest}
          onClick={() => showModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID)}
        >
          {sendValidationBtnLabel}
        </Button>
      )}

      {/* Send validation request confirmation modal */}
      <Modal
        id={SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID)}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation.isLoading}
          onClick={enrolmentMutation.mutate}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    </div>
  );
}

ProfileDocuments.propTypes = {
};

export default ProfileDocuments;
