import decode from 'jwt-decode';

import useProfile from '../hooks/useProfile';
import { RENT_DISTRIBUTION_STATUS } from './constants';

export const getUserFromToken = (token) => {
  try {
    const user = decode(token);
    return user;
  } catch (error) {
    return null;
  }
};

export const doesUserHaveRole = (userRoles, roles) => {
  if (!userRoles || !roles) {
    return false;
  }

  return roles.some((role) => userRoles.includes(role));
};

export const checkIfSendProfileValidationRequest = (data) => {
  const { profile } = useProfile();
  if (!profile && !data) return false;
  const rentDistribution = data || profile?.rentDistribution;
  return [
    RENT_DISTRIBUTION_STATUS.ONLY_WITH_PERSONAL_ACCOUNT,
    RENT_DISTRIBUTION_STATUS.WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT,
  ].includes(rentDistribution);
};
