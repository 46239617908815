/* eslint-disable max-len */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useMatch } from 'react-router-dom';

// Components
import { Picto, Tag, utils } from 'ui-library-unlocker';
import PropertyForm from '../../components/organisms/forms/PropertyForm/PropertyForm';
import PropertyCharacteristicsForm from '../../components/organisms/forms/PropertyForm/PropertyCharacteristicsForm';
import PropertyDocuments from './PropertyDocuments/PropertyDocuments';
import PropertyInvoices from './PropertyInvoices/PropertyInvoices';
import PropertyPictures from './PropertyPictures/PropertyPictures';

// Services
import { getProperty } from '../../services/property';
import { getPropertyFromAdmin } from '../../services/admin';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

// Utils
import { getStatusStyles } from '../../utils/properties';

// Constants
import { ENROLMENT_STATUS } from '../../utils/constants';

import styles from './PropertyEdit.module.scss';

function PropertyEdit({ isEditing }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { context: { me } } = useAppContext();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();

  const match = useMatch('/property/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#characteristics':
        document.title = t('property.tabs.characteristics.browserTitle');
        break;
      case '#documents':
        document.title = t('property.tabs.documents.browserTitle');
        break;
      case '#invoices':
        document.title = t('property.tabs.invoices.browserTitle');
        break;
      case '#pictures':
        document.title = t('property.tabs.pictures.browserTitle');
        break;
      case '#comments':
        document.title = t('property.tabs.comments.browserTitle');
        break;
      default:
        document.title = t('property.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const propertyQuery = useQuery({
    queryKey: ['property-id', isEditing],
    queryFn: () => {
      if (isEditing && match?.params?.id !== 'add') {
        if (isUserAdmin) {
          return getPropertyFromAdmin(match?.params?.id);
        }
        return getProperty(match?.params?.id);
      }
      return null;
    },
    cacheTime: 0,
  });

  const isPropertyVisibilityInWaitingKYC = propertyQuery.data?.data?.visibility === 'waiting_kyc';
  const isOwnerPending = (
    me?.onboardingStatus === ENROLMENT_STATUS.ENROLMENT_PENDING
    && isPropertyVisibilityInWaitingKYC
    && !isUserRealEstateManager
  );

  const tabs = useMemo(() => [
    {
      label: t('property.tabs.generalInfo.tabTitle'),
      title: t('property.tabs.generalInfo.title'),
      hash: '',
      show: true,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PropertyForm isEditing={isEditing} propertyQuery={propertyQuery} />
      ),
    },
    {
      label: t('property.tabs.characteristics.tabTitle'),
      title: t('property.tabs.characteristics.title'),
      hash: 'characteristics',
      show: true,
      activePicto: <Picto style={{ bottom: '-13px' }} className="m-t-30" color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <PropertyCharacteristicsForm />
      ),
    },
    {
      label: t('property.tabs.documents.tabTitle'),
      title: t('property.tabs.documents.title'),
      hash: 'documents',
      show: true,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <PropertyDocuments />
      ),
    },
    {
      label: t('property.tabs.invoices.tabTitle'),
      title: t('property.tabs.invoices.title'),
      hash: 'invoices',
      show: true,
      activePicto: <Picto style={{ bottom: '-13px' }} color="var(--color-secondary)" width="100%" icon="trait-vague-701" />,
      component: (
        <PropertyInvoices />
      ),
    },
    {
      label: t('property.tabs.pictures.tabTitle'),
      title: t('property.tabs.pictures.title'),
      hash: 'pictures',
      show: true,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trait-vague-701" />,
      component: (
        <PropertyPictures />
      ),
    },
    {
      label: t('property.tabs.comments.tabTitle'),
      title: t('property.tabs.comments.title'),
      hash: 'comments',
      show: false,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <PropertyForm isEditing={isEditing} />
      ),
    },
  ], [t, location, isEditing, propertyQuery]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash && cur.show);
    return tab || tabs[0];
  }, [tabs, location]);

  const status = useMemo(() => {
    if (isPropertyVisibilityInWaitingKYC) {
      if (isUserAdmin) return 'waiting_kyc';
      if (isOwnerPending) return 'waiting_kyc_owner';
      return 'waiting_kyc_manager';
    }
    return propertyQuery?.data?.data?.status;
  }, [isPropertyVisibilityInWaitingKYC, isOwnerPending, propertyQuery, isUserAdmin]);

  const { variant, icon } = getStatusStyles(status || '');

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>{isEditing ? t('property.crud.editTitle') : t('property.crud.addTitle')}</h1>
          {isEditing && propertyQuery?.data && (
            <>
              <ul className={styles.tabList}>
                {tabs.filter((tab) => tab.show).map((tab) => {
                  const isActive = tab.hash === currentTab.hash;

                  return (
                    <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                      <Link to={`#${tab.hash}`}>{tab.label}</Link>
                      {isActive && tab.activePicto}
                    </li>
                  );
                })}
              </ul>
              <div className={utils.cn(['m-t-20', styles.editDetails])}>
                <h2 className="t-h2-500">{propertyQuery?.data?.data?.name}</h2>
                <Tag
                  label={t(`property.table.status.${status}`)}
                  variant={variant}
                  icon={icon}
                  size="medium"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

PropertyEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default PropertyEdit;
