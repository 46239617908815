/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto, Tag, Button, utils, Table,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../components/organisms/DataTable/DataTable';
import ExportModal from '../../components/molecules/ExportModal/ExportModal';

// Services
import { getOwners } from '../../services/owner';
import { getOwnersFromAdmin } from '../../services/admin';

// Utils
import { showModal } from '../../utils/modal';

// Hooks
import useRoles from '../../hooks/useRoles';

// Constants
import { ENROLMENT_STATUS } from '../../utils/constants';

import styles from './OwnersList.module.scss';

const EXPORT_MODAL_ID = 'owner-export-modal';

function OwnerCompaniesList({
  data,
  exportOwner,
  setSelectedCompany,
}) {
  const { companies } = data;
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState(null);

  const statusVariant = useMemo(() => (status) => {
    if (!status) return null;
    switch (status) {
      case ENROLMENT_STATUS.PENDING:
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
        return 'tertiary';

      case ENROLMENT_STATUS.PROFILE_DONE:
        return 'primary';

      case ENROLMENT_STATUS.BLOCKED:
        return 'error';

      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return 'success';

      default:
        return 'tertiary';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('owner.table.columns.company'),
      accessorKey: 'legalName',
      size: 500,
    },
    {
      header: t('company.table.columns.onboardingStatus'),
      accessorKey: 'onboardingStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { onboardingStatus } } }) => (
        <div>
          <Tag
            label={t(`global.companyStatus.${onboardingStatus}`)}
            size="medium"
            variant={statusVariant(onboardingStatus)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 140,
      enableSorting: false,
      cell: ({ row }) => {
        const options = [
          !exportOwner ? null : {
            id: 'export',
            label: t('owner.table.listOptions.export'),
            icon: <Picto icon="export-link" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="export-link" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              setSelectedCompany(row?.original);
              showModal(EXPORT_MODAL_ID);
            },
          },
        ];
        return options?.filter((elem) => elem != null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-company-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-company-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, exportOwner]);

  return (
    <div className={styles.companiesList}>
      {companies?.length ? (
        <Table
          fullWidth
          columns={columns}
          data={companies}
          disableHover
        />
      ) : (
        <span className={styles.noCompanies}>{t('owner.noCompanies')}</span>
      )}
    </div>
  );
}

OwnerCompaniesList.defaultProps = {
  data: {
    companies: [],
  },
  exportOwner: false,
  setSelectedCompany: () => {},
};

OwnerCompaniesList.propTypes = {
  data: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape()),
  }),
  exportOwner: PropTypes.bool,
  setSelectedCompany: PropTypes.func,
};

function OwnersListView() {
  const { t } = useTranslation();
  const { isUserAdmin } = useRoles();
  const navigate = useNavigate();
  const {
    exportOwner,
  } = useFlags();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = t('owner.browserTitle');
  }, [t]);

  const statusVariant = useMemo(() => (s, invite) => {
    if (invite) {
      return 'tertiary';
    }
    return s === 'enabled' ? 'secondary-outline' : 'error';
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('owner.table.columns.id'),
      accessorKey: 'unlockerId',
      size: 100,
    },
    {
      header: t('owner.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('owner.table.columns.name'),
      accessorKey: 'lastName',
      minSize: 100,
      cell: ({ row: { original: { firstName, lastName } } }) => (
        <span>
          {firstName}
          {' '}
          {lastName}
        </span>
      ),
    },
    {
      header: t('owner.table.columns.phone'),
      accessorKey: 'phoneNumber',
      size: 100,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            phoneNumber, phoneCountryCode, mobilePhoneNumber, mobilePhoneCountryCode,
          },
        },
      }) => (
        <div>
          <p className="p-2-500">
            {utils.getCompletePhoneNumber({ phone: mobilePhoneNumber, countryCode: mobilePhoneCountryCode })}
          </p>
          {phoneNumber != null
            ? (
              <p className="p-2-500">
                {utils.getCompletePhoneNumber({ phone: phoneNumber, countryCode: phoneCountryCode })}
              </p>
            )
            : null}
        </div>
      ),
    },
    {
      header: t('owner.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status, underPendingInvitation } } }) => (
        <div>
          <Tag
            label={t(`global.personStatus.${underPendingInvitation ? 'isPendingInvitation' : status}`)}
            size="medium"
            variant={statusVariant(status, underPendingInvitation)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => {
        const options = [
          row?.original?.underPendingInvitation ? null : {
            id: 'edit',
            label: t('global.listOptions.see'),
            icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              navigate(`/owner/${row?.original?.uid}`);
            },
          },
          row?.original?.underPendingInvitation || !exportOwner ? null : {
            id: 'export',
            label: t('owner.table.listOptions.export'),
            icon: <Picto icon="export-link" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="export-link" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              setSelectedOwner(row?.original);
              showModal(EXPORT_MODAL_ID);
            },
          },
        ];
        return options?.filter((elem) => elem != null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-owner-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-owner-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, exportOwner]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'status',
      type: 'checkbox',
      title: t('owner.table.columns.status'),
      options: [
        {
          value: 'enabled',
          label: t('owners.table.status.enabled'),
        },
        {
          value: 'disabled',
          label: t('owners.table.status.disabled'),
        },
      ],
    },
  ], []);

  const entityToExport = useMemo(() => {
    if (selectedOwner) {
      return {
        uid: selectedOwner.uid,
        name: `${selectedOwner.firstName} ${selectedOwner.lastName}`,
        type: 'owner',
      };
    }
    if (selectedCompany) {
      return {
        uid: selectedCompany.uid,
        name: selectedCompany.legalName,
        type: 'company',
      };
    }
    return null;
  }, [selectedOwner, selectedCompany]);

  const openedRows = useMemo(
    () => data
      .filter((owner) => owner.companies?.length > 0)
      .map((owner) => owner.uid),
    [data],
  );

  const handleExportClose = useCallback(() => {
    setSelectedOwner(null);
    setSelectedCompany(null);
  }, []);

  return (
    <div onClick={() => setTooltipOpen(null)}>
      <div className={styles.heading}>
        <h1>{t('owner.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('owners.search')}
        getData={isUserAdmin ? getOwnersFromAdmin : getOwners}
        setData={setData}
        queryKey="owner-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/owner/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('owner.addOwner')}
            </Button>
          </Link>
        )}
        openable
        alreadyOpened={openedRows}
      >
        <OwnerCompaniesList
          exportOwner={exportOwner}
          setSelectedCompany={setSelectedCompany}
        />
      </DataTable>
      <ExportModal
        idModal={EXPORT_MODAL_ID}
        entity={entityToExport}
        onClose={handleExportClose}
      />
    </div>
  );
}

export default OwnersListView;
