import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { utils, TextInput, Button } from 'ui-library-unlocker';
import TagManager from 'react-gtm-module';

import { useAppContext } from '../../../../store/context';

import { loginUser, forgotUserPassword, getMe } from '../../../../services/identity';

import { getUserFromToken, doesUserHaveRole } from '../../../../utils/user';
import { ROLES } from '../../../../utils/constants';

// Form schemas
import loginSchema, { loginInitialValues } from '../../../../utils/forms/loginSchema';
import forgotPasswordSchema, { forgotPasswordInitialValues } from '../../../../utils/forms/forgotPasswordSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';

import styles from './LoginForm.module.scss';

function LoginForm() {
  const { t } = useTranslation();
  const { hash, state: locationState } = useLocation();
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (response) => {
      const status = response?.status || response?.response?.status;
      switch (status) {
        case 200: {
          const { token, refreshToken } = response?.data?.accessToken || {};
          const userToken = getUserFromToken(token);

          const isUserAllowed = doesUserHaveRole(userToken?.['cognito:groups'] || [], [
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_USER,
            ROLES.ROLE_LESSOR,
          ]);

          if (!isUserAllowed) {
            utils.toast.error(t('login.form.errorResponses.notAllowed'));
            return;
          }

          localStorage.setItem('accessToken', token);
          localStorage.setItem('refreshToken', refreshToken);

          dispatch({
            type: 'SET_ROLE_SPACE',
            payload: null,
          });

          const fetchMe = async () => {
            const me = await getMe();
            return me?.data;
          };

          fetchMe().then((me) => {
            dispatch({
              type: 'SET_ME',
              payload: me,
            });
            dispatch({
              type: 'SET_AUTH',
              payload: {
                accessToken: token,
                refreshToken,
                user: userToken,
              },
            });
            if (locationState?.from) {
              navigate(locationState.from);
            }
          });
          break;
        }
        default:
          break;
      }
    },
    onError: (error) => {
      setLoading(false);
      switch (error?.response?.status) {
        case 401: {
          utils.toast.error(t('login.form.errorResponses.401'));
          break;
        }
        case 400: {
          switch (error?.response?.data?.message) {
            case 'ERR_IDENTITY_ACCESS_FORM_ERROR_001': {
              // utils.toast.alert(t('login.form.errorResponses.ERR_IDENTITY_ACCESS_FORM_ERROR_001'));
              utils.toast.alert(t('login.form.errorResponses.401'));
              break;
            }
            default:
              break;
          }
          break;
        }
        case 403: {
          switch (error?.response?.data?.message) {
            case 'ERR_IDENTITY_ACCOUNT_NOT_CONFIRMED': {
              utils.toast.alert(t('login.form.errorResponses.ERR_IDENTITY_ACCOUNT_NOT_CONFIRMED'));
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.generic'));
          break;
      }
    },
  });

  const forgotPasswordMutation = useMutation({
    mutationFn: forgotUserPassword,
    onSuccess: (/* { response } */) => {
      utils.toast.success(t('forgotPassword.form.success'));
      // switch (response.status) {
      //   case 200:
      //     utils.toast.success(t('forgotPassword.form.success'));
      //     break;
      //   case 400: {
      //     switch (response?.data?.message) {
      //       case 'IDENTITY_ACCESS_FORGOT_PASSWORD_USER_NOT_FOUND': {
      //         // utils.toast.alert(t('login.form.errorResponses.ERR_IDENTITY_ACCESS_FORM_ERROR_001'));
      //         break;
      //       }
      //       default:
      //         break;
      //     }
      //     break;
      //   }
      //   default:
      //     break;
      // }
    },
    onError: (error) => {
      if (error?.response) {
        switch (error?.response?.status) {
          case 417: {
            utils.toast.error(t('forgotPassword.form.accountUnconfirmed'));
            navigate('/register#resend-code');
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
        },
      });
      setLoading(true);
      mutation.mutate({
        grant_type: 'password',
        ...values,
      });
    },
  });

  const forgotPasswordFormik = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: forgotPasswordMutation.mutate,
  });

  const formType = hash === '#forgot-password' ? 'forgot-password' : 'login';

  return (
    <>
      {formType === 'login' && (
      <form onSubmit={errorFocusSubmit(formik.handleSubmit)}>
        <TextInput
          type="text"
          id="username"
          name="username"
          autoComplete="email"
          label={t('login.form.username')}
          error={displayError(t, formik, 'username')}
          valid={isFieldValid(formik, 'username')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
        />
        <TextInput
          type="password"
          id="password"
          name="password"
          className="m-t-25"
          label={t('login.form.password')}
          error={displayError(t, formik, 'password')}
          valid={isFieldValid(formik, 'password')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        <p className={styles.forgotPassword}>
          <Link to="/login#forgot-password">
            {t('login.form.forgotPassword')}
          </Link>
          <Link to="/register#resend-code">
            {t('register.resendCode.title')}
          </Link>
        </p>
        <Button
          type="submit"
          className="m-t-30 center-block"
          size="large"
          label={t('login.form.submit')}
          loading={mutation.isLoading || loading}
        />
        <p className={styles.noAccount}>
          {t('login.noAccount')}
          <Link to="/register">
            {t('login.signUp')}
          </Link>
        </p>
      </form>
      )}
      {formType === 'forgot-password' && (
        <>
          <p className={styles.forgotPasswordTitle}>
            {t('forgotPassword.title')}
          </p>
          <form onSubmit={forgotPasswordFormik.handleSubmit}>
            <TextInput
              type="text"
              id="username"
              name="username"
              label={t('login.form.username')}
              error={displayError(t, forgotPasswordFormik, 'username')}
              valid={isFieldValid(forgotPasswordFormik, 'username')}
              onChange={forgotPasswordFormik.handleChange}
              onBlur={forgotPasswordFormik.handleBlur}
              value={forgotPasswordFormik.values.username}
              info={t('forgotPassword.form.usernameHelp')}
            />
            <p className={styles.forgotPassword}>
              <Link to="/login">
                {t('forgotPassword.backToLogin')}
              </Link>
            </p>
            <Button
              type="submit"
              className="m-t-30 center-block"
              size="large"
              label={t('global.form.submit')}
              loading={forgotPasswordMutation.isLoading}
            />
          </form>
        </>
      )}
    </>
  );
}

export default LoginForm;
