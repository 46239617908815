import api from './_client';

export const getPaymentAccounts = ({ page, itemsPerPage, filters = {} }) => {
  const exactDatePlusOneDay = (date) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString();
  };
  const apiFilters = {
    'filters[uid][--in--]': filters.id?.join?.(','),
    'filters[enrolmentStatus][--in--]': filters.enrolmentStatus?.join?.(','),
    'filters[createdAt][--lte--]': filters.createdAtDate?.[0]
      ? exactDatePlusOneDay(filters.createdAtDate?.[0])
      : filters.createdAtEndDate?.[0],
    'filters[createdAt][--gte--]': filters.createdAtDate?.[0] || filters.createdAtStartDate?.[0],
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/account', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getPaymentAccount = (uid) => api.get(`/payment/account/${uid}`);

export const submitPaymentAccountEnrolment = (uid) => api.post(`/payment/admin/enrolment/submit/${uid}`);
