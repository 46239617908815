/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// Components
import {
  Button,
  CheckboxGroup,
  TextInput,
} from 'ui-library-unlocker';
import SideModal from '../SideModal/SideModal';
import DateFilter from '../DateFilter/DateFilter';

// Hooks
import useFilters from '../../../hooks/useFilters';

import styles from './FiltersSideModal.module.scss';

function FiltersSideModal({
  open,
  onClose,
  allOptions,
  onSubmit,
}) {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const formik = useFormik({
    initialValues: {
      ...filters,
    },
  });

  useEffect(() => {
    formik.setValues(filters);
  }, [filters]);

  const generateFormGroup = useCallback((type) => {
    switch (type) {
      case 'checkbox':
        return ({ name, options }) => (
          <CheckboxGroup
            name={name}
            value={formik.values[name]}
            onChange={(v) => formik.setFieldValue(name, v)}
            options={options}
          />
        );
      case 'price':
        return ({ name }) => (
          <div className={styles.filterInputGroup}>
            <TextInput
              className={styles.filterInput}
              label={t('global.filters.priceMin')}
              type="number"
              id={`${name}PriceMin`}
              name={`${name}PriceMin`}
              value={formik.values[`${name}PriceMin`]?.[0] || formik.values[`${name}PriceMin`]}
              onChange={formik.handleChange}
            />
            <TextInput
              className={styles.filterInput}
              label={t('global.filters.priceMax')}
              type="number"
              id={`${name}PriceMax`}
              name={`${name}PriceMax`}
              value={formik.values[`${name}PriceMax`]?.[0] || formik.values[`${name}PriceMax`]}
              onChange={formik.handleChange}
            />
          </div>
        );
      case 'date':
        return ({ name }) => {
          const date = formik.values[`${name}Date`];
          const startDate = formik.values[`${name}StartDate`];
          const endDate = formik.values[`${name}EndDate`];
          return (
            <DateFilter
              name={name}
              date={Array.isArray(date) ? date[0] : date}
              startDate={Array.isArray(startDate) ? startDate[0] : startDate}
              endDate={Array.isArray(endDate) ? endDate[0] : endDate}
              onChange={(d, dateName) => formik.setFieldValue(dateName, d)}
            />
          );
        };
      default:
        return () => null;
    }
  }, [formik]);

  const handleSubmit = useCallback(() => {
    onSubmit(formik.values);
  }, [onSubmit, formik.values]);

  return (
    <SideModal
      open={open}
      onClose={onClose}
      title={t('global.filters.title')}
    >
      <div className={styles.filters}>
        {allOptions?.map((option) => (
          <div key={option.name} className={styles.filterGroup}>
            <h3>{option.title}</h3>
            {generateFormGroup(option.type)(option)}
          </div>
        ))}
      </div>
      <div className={styles.stickyBottom}>
        <hr className={styles.filterSeparator} />
        <div className={styles.filterActions}>
          <p
            tabIndex={0}
            role="button"
            onKeyDown={null}
            className={styles.reinit}
            onClick={formik.resetForm}
          >
            {t('global.filters.reinit')}
          </p>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit}
          >
            {t('global.filters.submit')}
          </Button>
        </div>
      </div>
    </SideModal>
  );
}

FiltersSideModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allOptions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  onSubmit: PropTypes.func.isRequired,
};

FiltersSideModal.defaultProps = {
  allOptions: [],
};

export default FiltersSideModal;
