import {
  cloneElement,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';

// Components
import {
  utils,
  TextInput,
  PhoneInput,
  DateInput,
  ToggleInput,
} from 'ui-library-unlocker';

// Services
import { patchUserProfile } from '../../../services/profile';
import { checkExistingSIRET, updateOnboardingProgress } from '../../../services/onboarding';
import { updateCompany } from '../../../services/company';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { displayError, errorFocusSubmit, isFieldValid } from '../../../utils/forms/form';
import {
  onboardingProfile1InitialValues,
  onboardingProfile1Schema,
} from '../../../utils/forms/onboardingSchema';
import { legalCategories } from '../../../utils/forms/companyDetailsSchema';
import { twentyYearsBackDate } from '../../../utils/dates';

import styles from './OnboardingTunnelProfile1.module.scss';

// idNumber = siret

function OnboardingTunnelProfile1({
  children: footer,
  handleStep,
  endOnboardingMutation,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      inputtedData,
      error,
      companyData,
    },
    dispatchOnboarding,
    refetchProfile,
    onboardingRole: {
      isTenant, role,
    },
    refetchCompany,
  } = useOnboardingContext();

  const initialValues = useMemo(() => {
    if (error) {
      return onboardingProfile1InitialValues;
    }
    if (inputtedData.profile) {
      const userBirthDate = inputtedData.profile.birthDate;
      const profileInitialValues = {
        ...inputtedData.profile,
        birthDate: userBirthDate ? new Date(userBirthDate) : twentyYearsBackDate(),
        taxCountry: 'FR',
        hasAtLeastOneCompany: !!inputtedData.company?.idNumber,
        idNumber: inputtedData.company?.idNumber || '',
      };
      if (companyData?.data) {
        return {
          ...profileInitialValues,
          hasAtLeastOneCompany: true,
          idNumber: companyData.data.idNumber,
        };
      }
      return profileInitialValues;
    }
    return onboardingProfile1InitialValues;
  }, [inputtedData, error, companyData]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        onboardingProfile1Schema.validateSync(values, {
          context: {
            onboardingRole: role,
          },
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, er) => {
          errors[er.path] = er.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (mustHaveValidSiret && !isSIRETExisting) return null;
      if (JSON.stringify(values) === JSON.stringify(initialValues)) return handleStep(1);
      if (isTenant) return profileMutation.mutate(values);
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: {
          ...inputtedData,
          profile: values,
          hasAtLeastOneCompany: values.hasAtLeastOneCompany,
          company: {
            ...inputtedData.company,
            idNumber: values.idNumber,
          },
        },
      });
      // If company already exists and the SIRET has changed, edit the company
      if (companyData?.data && companyData.data.idNumber !== values.idNumber) {
        return companyMutation.mutate(values.idNumber);
      }
      // Save progression
      return progressMutation.mutate({
        ...values,
        idNumber: values.hasAtLeastOneCompany ? values.idNumber : null,
      });
    },
  });

  const mustHaveValidSiret = useMemo(() => (
    !isTenant && formik.values.hasAtLeastOneCompany
  ), [isTenant, formik.values.hasAtLeastOneCompany]);

  const profileMutation = useMutation({
    mutationFn: patchUserProfile,
    onSuccess: () => {
      if (isTenant) {
        endOnboardingMutation.mutate();
        refetchProfile();
      }
    },
    onError: (err) => {
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const checkSIRETMutation = useMutation({
    mutationFn: checkExistingSIRET,
    keepPreviousData: true,
    onSuccess: ({ data }) => {
      if (!data) return;
      const compayPreFilledData = {
        idNumber: formik.values.idNumber,
      };
      if (data.legalName && !inputtedData.company?.legalName) {
        compayPreFilledData.legalName = data.legalName;
      }
      if (data.brandName && !inputtedData.company?.brandName) {
        compayPreFilledData.brandName = data.brandName;
      }
      if (data.registrationDate && !inputtedData.company?.registrationDate) {
        compayPreFilledData.registrationDate = new Date(data.registrationDate);
      }
      if (data.headQuarters?.registrationNumber && !inputtedData.company?.registrationNumber) {
        compayPreFilledData.registrationNumber = data.registrationNumber;
      }
      if (data.legalCategoryLabel && !inputtedData.company?.legalCategory) {
        // eslint-disable-next-line max-len
        compayPreFilledData.legalCategory = legalCategories.find((cat) => cat.value === data.legalCategoryLabel)?.value;
      }
      if (data.headQuarters && !inputtedData.company?.address) {
        compayPreFilledData.address = data.headQuarters;
      }
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: {
          ...inputtedData,
          company: {
            ...inputtedData.company,
            ...compayPreFilledData,
          },
          beneficiaries: Array.isArray(data.beneficiaries) ? data.beneficiaries : [],
        },
      });
    },
  });

  const companyMutation = useMutation({
    mutationFn: (idNumber) => updateCompany(companyData.data.uid, { ...companyData.data, idNumber }),
    onSuccess: ({ data }) => {
      refetchCompany();
      // Save progression
      return progressMutation.mutate({
        ...formik.values,
        idNumber: data?.idNumber,
      });
    },
    onError: (err) => {
      if (err?.response?.status === 409 && err.response.data?.message === 'PERSON_COMPANY_CONFLICT') {
        return utils.toast.error(t(`company.crud.formErrors.${err.response.data?.message}`));
      }
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const progressMutation = useMutation({
    mutationFn: (data) => updateOnboardingProgress('step1', data),
    onSettled: () => handleStep(1),
  });

  const isSIRETValid = useMemo(() => (
    formik.values.idNumber && formik.values.idNumber.length === 14
  ), [formik.values.idNumber]);

  useEffect(() => {
    if (isSIRETValid) {
      checkSIRETMutation.mutate(formik.values.idNumber);
    }
  }, [isSIRETValid, formik.values.idNumber]);

  const isSIRETExisting = useMemo(() => (
    isSIRETValid
      ? (!!checkSIRETMutation.data || formik.values.idNumber === companyData?.data?.idNumber)
      : false
  ), [checkSIRETMutation.data, isSIRETValid, companyData, formik.values.idNumber]);

  const checkSIRETError = useMemo(() => {
    if (
      checkSIRETMutation.isError
      && checkSIRETMutation.error?.response?.status === 409
      && checkSIRETMutation.error?.response?.data?.message === 'PERSON_COMPANY_CONFLICT'
    ) {
      if (formik.values.idNumber !== companyData?.data?.idNumber) {
        return t('company.crud.formErrors.PERSON_COMPANY_CONFLICT');
      }
      return false;
    }
    if (checkSIRETMutation.isError) {
      return t('onboarding.steps.profile1.siretError');
    }
    return false;
  }, [checkSIRETMutation.isError, t, formik.values.idNumber, companyData]);

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const birthDateValue = useMemo(() => {
    try {
      if (!formik.values.birthDate) return null;
      return new Date(formik.values.birthDate);
    } catch {
      return null;
    }
  }, [formik.values.birthDate]);

  const shouldDisplayProfileInputs = useMemo(() => {
    if (isTenant) return true;
    if (!formik.values.hasAtLeastOneCompany) return true;
    if (formik.values.hasAtLeastOneCompany && isSIRETExisting) return true;
    return false;
  }, [isTenant, formik.values.hasAtLeastOneCompany, isSIRETExisting]);

  const shouldDisplaySIRETInput = useMemo(() => (
    !isTenant && formik.values.hasAtLeastOneCompany
  ), [isTenant, formik.values.hasAtLeastOneCompany]);

  return (
    <>
      <div className={styles.profile1}>
        <h2 className="m-b-10">
          {t('onboarding.steps.profile1.title')}
        </h2>
        <form className={styles.form} onSubmit={errorFocusSubmit(handleSubmit)}>
          {!isTenant && (
            <ToggleInput
              id="hasAtLeastOneCompany"
              name="hasAtLeastOneCompany"
              className="m-t-25 m-b-30"
              label={t('onboarding.steps.profile1.hasAtLeastOneCompany')}
              checked={!!formik.values.hasAtLeastOneCompany}
              onChange={(check) => formik.setFieldValue('hasAtLeastOneCompany', check)}
            />
          )}
          {shouldDisplaySIRETInput && (
            <div className={styles.inputGroup}>
              <TextInput
                type="text"
                id="idNumber"
                name="idNumber"
                className="m-t-30"
                label={t('company.crud.form.idNumber')}
                error={displayError(t, formik, 'idNumber') || checkSIRETError}
                valid={isSIRETExisting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idNumber}
                loading={checkSIRETMutation.isLoading}
              />
            </div>
          )}
          {shouldDisplayProfileInputs && (
            <div className={styles.inputGroup}>
              <TextInput
                type="text"
                id="firstName"
                name="firstName"
                className="m-t-25"
                label={t('profile.personalInfo.form.firstName')}
                error={displayError(t, formik, 'firstName')}
                valid={isFieldValid(formik, 'firstName', null, initialValues?.firstName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              <TextInput
                type="text"
                id="lastName"
                name="lastName"
                className="m-t-25"
                label={t('profile.personalInfo.form.lastName')}
                error={displayError(t, formik, 'lastName')}
                valid={isFieldValid(formik, 'lastName', null, initialValues?.lastName)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              <PhoneInput
                id="mobilePhoneNumber"
                name="mobilePhoneNumber"
                label={t('profile.personalInfo.form.mobilePhone')}
                className="m-t-25"
                error={displayError(t, formik, 'mobilePhoneNumber')}
                valid={isFieldValid(formik, 'mobilePhoneNumber', null, initialValues?.mobilePhoneNumber)}
                value={{
                  countryCode: formik?.values?.mobilePhoneCountryCode,
                  phone: formik?.values?.mobilePhoneNumber,
                }}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  const { countryCode, phone } = value;
                  formik.setFieldValue('mobilePhoneCountryCode', countryCode);
                  formik.setFieldValue('mobilePhoneNumber', phone);
                }}
              />
              <DateInput
                id="birthDate"
                name="birthDate"
                className={utils.cn(['m-t-25', styles.dateInput])}
                label={t('profile.personalInfo.form.birthDate')}
                error={displayError(t, formik, 'birthDate')}
                valid={isFieldValid(formik, 'birthDate', null, initialValues?.birthDate)}
                onChange={(date) => formik.setFieldValue('birthDate', date)}
                onBlur={formik.handleBlur}
                value={birthDateValue}
              />
            </div>
          )}
        </form>
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isLoading: (
          profileMutation.isLoading
          || endOnboardingMutation.isLoading
          || companyMutation.isLoading
          || progressMutation.isLoading
        ),
        isDisabled: formik.values.hasAtLeastOneCompany && !isSIRETExisting,
      })}
    </>
  );
}

OnboardingTunnelProfile1.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
  endOnboardingMutation: PropTypes.shape({
    mutate: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
};

OnboardingTunnelProfile1.defaultProps = {
  children: null,
};

export default OnboardingTunnelProfile1;
