/* eslint-disable react/jsx-closing-bracket-location */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Picto, utils } from 'ui-library-unlocker';
import { useFlags } from 'launchdarkly-react-client-sdk';

import SwitchLessorTenantEnv from '../../atoms/SwitchLessorTenantEnv/SwitchLessorTenantEnv';

import { useAppContext } from '../../../store/context';

import { doesUserHaveRole } from '../../../utils/user';

import getMenuEntries from './menuEntries';

import styles from './Menu.module.scss';
import useRoles from '../../../hooks/useRoles';

import { ROLES } from '../../../utils/constants';

function Menu({ children }) {
  const {
    context: {
      accessToken,
      accessTokenBackup,
      user,
      sideMenuOpen,
      hasCompletedOnboardingFunnel,
      skipOnboarding,
      roleSpace,
    },
    dispatch,
  } = useAppContext();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { paymentpage } = useFlags();

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT_USER' });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('hasCompletedOnboardingFunnel');
    localStorage.removeItem('roleSpace');
  };

  const handleUnimpersonate = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenBackup'));
    localStorage.setItem('refreshToken', localStorage.getItem('refreshTokenBackup'));
    localStorage.removeItem('accessTokenBackup');
    localStorage.removeItem('refreshTokenBackup');
    localStorage.removeItem('impersonateIdentity');
    localStorage.setItem('hasCompletedOnboardingFunnel', localStorage.getItem('hasCompletedOnboardingFunnelBackup'));
    localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
    localStorage.removeItem('roleSpace');
    // eslint-disable-next-line no-restricted-globals
    location.replace('/');
  };

  const menuRoutes = useMemo(
    () => getMenuEntries(accessToken, t, isUserAdmin, paymentpage),
    [accessToken, t, isUserAdmin, paymentpage],
  );

  const impersonated = JSON.parse(localStorage.getItem('impersonateIdentity'));

  const renderMenu = useCallback(() => (
    <>
      <div className={styles.menuSections}>
        {!isUserAdmin && (
          <SwitchLessorTenantEnv />
        )}
        {Object.keys(menuRoutes)
          .filter((key) => {
            const { displayFor, hideFor } = menuRoutes[key];

            if (!displayFor) return true;
            const cognitoGroups = roleSpace === 'tenant'
              ? [ROLES.ROLE_USER]
              : user['cognito:groups'];
            if (doesUserHaveRole(cognitoGroups, hideFor)) return false;
            if (!doesUserHaveRole(cognitoGroups, displayFor)) return false;
            return true;
          })
          .map((key) => (
            <div key={key} className={styles.menuSection}>
              {menuRoutes[key].title && (
              <div className={styles.menuHeading}>
                {menuRoutes[key].title}
              </div>
              )}
              <ul className={styles.menuList}>
                {menuRoutes[key].links.map((link) => {
                  if (!user) return null;
                  const cognitoGroups = roleSpace === 'tenant'
                    ? [ROLES.ROLE_USER]
                    : user['cognito:groups'];
                  if (doesUserHaveRole(cognitoGroups, link?.hideFor)) return null;
                  if (!doesUserHaveRole(cognitoGroups, link?.roles)) return null;
                  if (link?.onlyManagers && !isUserRealEstateManager) return null;

                  let isActive = link.match?.some((match) => pathname.includes(match));
                  if (link?.match?.[0] === '/' && pathname !== '/') isActive = false;
                  return (
                    <li key={link.label} className={utils.cn([styles.menuItem, isActive ? styles.active : null])}>
                      {link?.external
                        ? (
                          <a target="_blank" href={link?.to} rel="noreferrer">
                            {link?.icon && (
                            <div className={styles.menuIcon}>
                              {link?.icon}
                            </div>
                            )}
                            {link?.label}
                            <Picto className="m-l-8" color="var(--color-white)" width={11} icon="export-link" />
                          </a>
                        )
                        : (
                          <Link
                            onClick={() => dispatch({
                              type: 'SET_SIDEMENU_OPEN',
                              payload: false,
                            })}
                            to={link.to}
                          >
                            {link?.icon && (
                            <div className={styles.menuIcon}>
                              {link?.icon}
                            </div>
                            )}
                            <div className={styles.menuLabel}>
                              {link?.label}
                              {isActive && (
                              <Picto color="var(--color-secondary)" width={37} icon="trace-3567" />
                              )}
                            </div>
                          </Link>
                        )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
      </div>
      <div className={styles.logout}>
        {impersonated != null ? (
          <div className={styles.impersonated}>
            <button type="button" onClick={() => handleUnimpersonate()} className={styles.logoutContent}>
              <Picto color="var(--color-white)" width={20} icon="logout" />
              {t('global.leave')}
              <br />
              {`${impersonated.firstName} ${impersonated.lastName}`}
            </button>
          </div>
        ) : (

          <button type="button" onClick={() => handleLogout()} className={styles.logoutContent}>
            <Picto color="var(--color-white)" width={20} icon="logout" />
            {t('global.logout')}
          </button>
        )}
      </div>
    </>
  ), [menuRoutes, pathname, user, isUserRealEstateManager, roleSpace]);

  return (
    <div className={isMobile ? styles.burger : styles.wrapper}>
      {(hasCompletedOnboardingFunnel || skipOnboarding || isUserAdmin) && (
        <div
          id="menu"
          className={
            isMobile
              ? utils.cn([
                styles.burgerMenu,
                sideMenuOpen ? styles.open : null,
                accessTokenBackup ? styles.impersonated : null,
              ])
              : styles.menu
          }
        >
          {renderMenu()}
        </div>
      )}
      <div className={utils.cn([
        isMobile ? null : styles.contentWrap,
        (hasCompletedOnboardingFunnel || skipOnboarding || isUserAdmin) ? null : styles.noMenu,
      ])}>
        <div
          id="menu-content"
          className={
            isMobile
              ? utils.cn([
                styles.burgerContent,
                sideMenuOpen ? styles.open : null,
                accessTokenBackup ? styles.impersonated : null,
              ])
              : styles.content
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Menu;
