/* eslint-disable react/prop-types */
import React, {
  forwardRef, useState, useEffect, useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Picto, TextInput, utils } from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../../../store/context';

// Utils
import { getAddressField } from '../../../utils/maps';

// Styles
import styles from './CityInput.module.scss';

const stylesSelect = {
  indicatorSeparator: () => {},
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  placeholder: (provided) => ({
    ...provided,
    opacity: 0,
  }),
  menu: (base) => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    position: 'absolute',
    left: 0,
  }),
};

function ValueContainer({ children, ...props }) {
  const {
    hasValue,
    selectProps: {
      name, isSearchable, inputValue, label,
    },
  } = props;
  return (
    <components.ValueContainer {...props}>
      <label
        htmlFor={name}
        className={utils.cn([
          styles.label,
          hasValue || inputValue ? styles.isFocused : '',
          isSearchable ? styles.isSearchable : '',
        ])}
      >
        {label}
      </label>
      {children}
    </components.ValueContainer>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Picto color="var(--color-secondary)" icon="arrow-down" width={24} />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <Picto icon="close" style={{ height: '18px' }} />
    </components.ClearIndicator>
  );
}

const CityInput = forwardRef((props, ref) => {
  const { context: { googleMaps } } = useAppContext();
  const {
    disabled,
    error,
    valid,
    info,
    onAddressSelect,
    value,
    label,
  } = props;

  const { className, ...inputProps } = props;

  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [listPlaceholder, setListPlaceholder] = useState(t('global.form.fields.cityNoOptions'));

  const placesService = useMemo(() => (
    new googleMaps.maps.places.PlacesService(document.createElement('div'))
  ), [googleMaps]);
  const autocompleteService = useMemo(() => new googleMaps.maps.places.AutocompleteService(), [googleMaps]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!searchInput) return;
      if (searchInput && searchInput.length < 3) {
        setSearchError(t('global.form.errors.addressAutoCompleteLength'));
        return;
      }
      setSearchError(null);
      try {
        setListPlaceholder(t('global.form.fields.addressLoading'));
        fetchAddressPredictions.mutate(searchInput);
      } catch (err) {
        if (err?.response) {
          //
        }
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const fetchAddressFromId = useMutation({
    mutationFn: async (id) => {
      const place = await new Promise((resolve, reject) => {
        placesService.getDetails({
          placeId: id,
          fields: ['address_component'],
        }, (p, status) => {
          if (status !== googleMaps.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(p);
        });
      });
      return place;
    },
    onSuccess: (response) => {
      const address = {
        zipCode: getAddressField(response, ['postal_code']),
        countryCode: getAddressField(response, ['country'], 'short_name'),
        city: getAddressField(response, [
          'locality',
          'sublocality',
          'postal_town',
          'administrative_area_level_3',
          // 'administrative_area_level_2',
          // 'administrative_area_level_1',
          // 'sublocality_level_3',
          // 'sublocality_level_2',
          // 'sublocality_level_1',
        ]),
      };
      if (!address.city || !address.countryCode) {
        utils.toast.error(t('global.form.errors.invalidAddress'));
        setSearchInput('');
        setOptions([]);
        return;
      }
      onAddressSelect(address);
    },
  });

  const fetchAddressPredictions = useMutation({
    mutationFn: async (textSearch) => {
      const predictions = await new Promise((resolve, reject) => {
        autocompleteService.getPlacePredictions({
          input: textSearch,
          types: ['locality', 'administrative_area_level_3', 'sublocality', 'postal_town'],
          fields: ['address_component'],
        }, (p, status) => {
          if (status !== googleMaps.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(p);
        });
      });
      return predictions;
    },
    onSuccess: (response) => {
      if (response?.length < 1) {
        setListPlaceholder(t('global.form.fields.cityNoResults'));
        setOptions([]);
        return;
      }
      setOptions(response?.map((addr) => ({
        label: addr?.description || 'No description',
        value: addr?.place_id,
      })));
      setListPlaceholder(t('global.form.fields.cityNoOptions'));
    },
  });

  const renderFreeSelect = useCallback(() => (
    <>
      {(searchError) && typeof searchError === 'string' ? (
        <div className={utils.cn([styles.topContext, styles.fieldError])}>
          {searchError}
        </div>
      ) : null}
      <Select
        ref={ref}
        isClearable
        isSearchable
        classNamePrefix="react-select"
        styles={stylesSelect}
        inputValue={searchInput}
        value={value}
        noOptionsMessage={() => listPlaceholder}
        options={options}
        filterOption={() => true}
        aria-invalid={error != null}
        onInputChange={(inputValue, { action }) => {
          if (action === 'input-blur') setSearchInput(searchInput ? searchInput.label : '');
          else if (action === 'input-change') setSearchInput(inputValue);
        }}
        onChange={(selected) => {
          setSearchInput(selected?.label || '');
          fetchAddressFromId.mutate(selected?.value);
        }}
        components={{
          DropdownIndicator, ClearIndicator, ValueContainer,
        }}
        {...inputProps}
      />
      {error && typeof error === 'string' ? (
        <div className={utils.cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
        <div className={utils.cn([styles.context, styles.fieldInfo])}>
          {info}
        </div>
      )}
    </>
  ), [value, options, searchInput, error, info]);

  const renderAddressDisplay = useCallback(() => (
    <div className={styles.addressGroup}>
      <TextInput
        type="text"
        id="street"
        name="street"
        label={label ?? t('global.form.fields.city')}
        valid
        disabled
        value={value}
      />
      <div className={styles.editAddress}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={null}
          className={styles.editAddressBtn}
          onClick={() => {
            onAddressSelect(null);
            setSearchInput('');
            setOptions([]);
          }}
        >
          {t('global.edit')}
        </div>
      </div>
    </div>
  ), [value, onAddressSelect, label]);

  return (
    <div className={utils.cn([
      styles.container,
      disabled ? styles.disabled : null,
      error ? styles.error : null,
      valid ? styles.valid : null,
      className,
    ])}
    >
      {!value && renderFreeSelect()}
      {value && renderAddressDisplay()}
    </div>
  );
});

CityInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  valid: PropTypes.bool,
  className: PropTypes.string,
  limitResults: PropTypes.number,
};

CityInput.defaultProps = {
  disabled: false,
  error: null,
  valid: false,
  className: '',
  limitResults: 10,
};

export default CityInput;
