/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  DateInput,
  SelectInput,
  Button,
} from 'ui-library-unlocker';

// Styles
import styles from './DateFilter.module.scss';

const TABS = {
  exact: 'exact',
  before: 'before',
  after: 'after',
  range: 'range',
};

const SHORTCUTS = {
  thisMonth: 'thisMonth',
  lastMonth: 'lastMonth',
  thisYear: 'thisYear',
  lastYear: 'lastYear',
};

function DateFilter(props) {
  const {
    className,
    name,
    onChange,
    date,
    startDate,
    endDate,
  } = props;

  const { t } = useTranslation();
  const [tab, setTab] = useState(TABS.exact);

  const resetTab = useCallback(() => {
    if (startDate && endDate) return setTab(TABS.range);
    if (date) return setTab(TABS.exact);
    if (endDate) return setTab(TABS.before);
    if (startDate) return setTab(TABS.after);
    return null;
  }, [date, startDate, endDate]);

  useEffect(() => {
    resetTab();
  }, []);

  const tabOptions = useMemo(() => Object.values(TABS).map((tb) => ({
    label: t(`global.filterDate.${tb}`),
    value: tb,
  })), [t]);

  const value = useMemo(() => {
    if (tab === TABS.exact) return date ? new Date(date) : null;
    if (tab === TABS.before) return endDate ? new Date(endDate) : null;
    if (tab === TABS.after) return startDate ? new Date(startDate) : null;
    return null;
  }, [tab, date, startDate, endDate]);

  const filterName = useMemo(() => {
    if (tab === TABS.exact) return `${name}Date`;
    if (tab === TABS.before) return `${name}EndDate`;
    if (tab === TABS.after) return `${name}StartDate`;
    return null;
  }, [tab]);

  const handleChange = useCallback((v) => {
    onChange(v, filterName);
  }, [onChange, filterName]);

  const handleRangeChange = useCallback(([start, end]) => {
    onChange(start, `${name}StartDate`);
    onChange(end, `${name}EndDate`);
  }, [onChange, name]);

  const handleTabChange = useCallback((item) => {
    if (item.value === tab) return;
    setTab(item.value);
    switch (item.value) {
      case TABS.exact:
        onChange(startDate || endDate, `${name}Date`);
        onChange(null, `${name}StartDate`);
        onChange(null, `${name}EndDate`);
        break;
      case TABS.before:
        onChange(startDate || date, `${name}EndDate`);
        onChange(null, `${name}Date`);
        onChange(null, `${name}StartDate`);
        break;
      case TABS.after:
        onChange(date || endDate, `${name}StartDate`);
        onChange(null, `${name}Date`);
        onChange(null, `${name}EndDate`);
        break;
      case TABS.range:
        onChange(null, `${name}Date`);
        onChange(null, `${name}StartDate`);
        onChange(null, `${name}EndDate`);
        break;
      default:
        break;
    }
  }, [setTab, tab, onChange, name, startDate, endDate, date]);

  const handleShortcut = useCallback((shortcut) => {
    let start;
    let end;
    switch (shortcut) {
      case SHORTCUTS.thisMonth:
        start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        break;
      case SHORTCUTS.lastMonth:
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        start = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
        end = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
        break;
      case SHORTCUTS.thisYear:
        start = new Date(new Date().getFullYear(), 0, 1);
        end = new Date(new Date().getFullYear(), 11, 31);
        break;
      case SHORTCUTS.lastYear:
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);
        start = new Date(lastYear.getFullYear(), 0, 1);
        end = new Date(lastYear.getFullYear(), 11, 31);
        break;
      default:
        break;
    }
    handleTabChange(tabOptions.find((item) => item.value === TABS.range));
    handleRangeChange([start, end]);
  }, [onChange, name, tab, tabOptions, handleTabChange]);

  return (
    <div className={utils.cn([styles.container, className])}>
      <SelectInput
        id={`${name}Tab`}
        className="m-b-15"
        name="filterDateTab"
        options={tabOptions}
        onChange={handleTabChange}
        value={tabOptions.find((item) => item.value === tab)}
      />
      {tab === TABS.range ? (
        <DateInput
          id={`${name}DateFilterRange`}
          name="filterDate"
          label={t('global.date')}
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          selectsRange
          onChange={handleRangeChange}
          full
          portalId={styles.datepickerMenu}
          isClearable
        />
      ) : (
        <DateInput
          id={`${name}DateFilter`}
          name="filterDate"
          label={t('global.date')}
          value={value}
          onChange={handleChange}
          full
          portalId={styles.datepickerMenu}
          isClearable
        />
      )}
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          onClick={() => handleShortcut(SHORTCUTS.thisMonth)}
          type="button"
          variant="grey"
          label={t(`global.filterDate.${SHORTCUTS.thisMonth}`)}
        />
        <Button
          className={styles.button}
          onClick={() => handleShortcut(SHORTCUTS.lastMonth)}
          type="button"
          variant="grey"
          label={t(`global.filterDate.${SHORTCUTS.lastMonth}`)}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          onClick={() => handleShortcut(SHORTCUTS.thisYear)}
          type="button"
          variant="grey"
          label={t(`global.filterDate.${SHORTCUTS.thisYear}`)}
        />
        <Button
          className={styles.button}
          onClick={() => handleShortcut(SHORTCUTS.lastYear)}
          type="button"
          variant="grey"
          label={t(`global.filterDate.${SHORTCUTS.lastYear}`)}
        />
      </div>
    </div>
  );
}

DateFilter.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateFilter.defaultProps = {
  className: '',
  date: null,
  startDate: null,
  endDate: null,
};

export default DateFilter;
