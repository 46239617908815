import api from './_client';

export const getPerson = (uid) => api.get(`/person/persons/${uid}`);

export const getPersonDocuments = (personUid, params) => api.get(`/person/persons/${personUid}/documents`, {
  params,
});

export const createUserDocument = ({
  type,
  name,
  documentUid,
  personUID,
}) => api.post('/person/documents', {
  type, name, documentUid, personUid: personUID,
});

export const deleteUserDocument = (documentUid) => api.delete(`/person/documents/${documentUid}`);

export const getTenantDocumentList = (tenantUid, params) => api.get(`/person/tenants/${tenantUid}/documents`, {
  params,
});

export const getOwnerDocumentList = (ownerUid, params) => api.get(`/person/property-owners/${ownerUid}/documents`, {
  params,
});

export const getManagers = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[onboardingStatus][--in--]': filters.onboardingStatus?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get('/person/managers', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const postToKnowYou = (data) => api.post('/person/onboarding/get-to-know-you', data);
