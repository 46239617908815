/* eslint-disable no-restricted-syntax */
import {
  cloneElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Components
import {
  Message,
} from 'ui-library-unlocker';
import CompanyDocumentsList from '../../../components/organisms/DocumentList/CompanyDocumentsList/CompanyDocumentsList';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { DOC_TYPES } from '../../../utils/constants';

import styles from './OnboardingTunnelCompany4.module.scss';

function OnboardingTunnelCompany4({
  children: footer,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      documentsCompanyData,
      companyData,
      inputtedData,
    },
    refetchDocumentsCompany,
  } = useOnboardingContext();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const documentsList = useMemo(() => documentsCompanyData?.data?.collection || [], [documentsCompanyData]);

  const displayMissingDocuments = useMemo(() => {
    if (!missingDocuments) return null;
    const docTypeEntries = Object.entries(DOC_TYPES);
    return (
      <>
        <p className="p-2-700 m-b-10">
          {t('profile.documents.missingDocuments')}
        </p>
        <ul className={styles.missingList}>
          {missingDocuments.map((doc) => {
            const docKey = docTypeEntries.find(([, value]) => value === doc?.toLowerCase())?.[0];
            return (
              <li key={doc}>{t(`global.docStep.${docKey}.title`)}</li>
            );
          })}
        </ul>
      </>
    );
  }, [missingDocuments, t]);

  // KBIS
  const kbisColumns = useMemo(() => ([
    {
      header: t('global.docStep.kbis.columns.date'),
      accessorKey: 'date',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { date } } }) => (
        <div>
          {date ? format(new Date(date), 'dd MM yyyy', { locale: fr }) : null}
        </div>
      ),
    },
  ]), [t]);

  const isAssociation = useMemo(() => companyData?.legalCategory === '92', [companyData]);

  const docSteps = useMemo(() => [
    // KBIS
    // RNA / JO / ARUP for associations
    {
      docType: isAssociation ? 'decree' : 'kbis',
      count: 1,
      additionalColumns: isAssociation ? [] : kbisColumns,
    },
    // Liste des bénéficiaires effectifs +25%
    {
      docType: 'beneficiariesList',
      count: 2,
    },
    // Bénéficiaires justificatifs identite
    {
      docType: ['benefIdentities', 'benefIdentities2', 'benefIdentities3'],
      optional: true,
      // optionalTitle = " " so that "(optional)" is not displayed next to the title.
      // The docs are optional for validation but the user must not think they are optional to provide.
      optionalTitle: ' ',
      textContent: inputtedData.beneficiaries?.length > 0 ? (
        <Message
          icon={null}
          content={(
            <>
              <p className="m-b-5">
                <Trans i18nKey="onboarding.steps.company4.needBenefIdProofForThesePeople" />
              </p>
              <ul>
                {inputtedData.beneficiaries?.map((benef) => (
                  <li key={`${benef.firstNames}${benef.lastName}`}>
                    {`${benef.firstNames} ${benef.lastName}`}
                  </li>
                ))}
              </ul>
            </>
          )}
          variant="info"
        />
      ) : null,
    },
  ], [isAssociation, kbisColumns, inputtedData.beneficiaries]);

  const handleSubmit = useCallback(() => {
    const missingDocs = [];
    for (const docStep of docSteps) {
      if (!docStep.optional && !documentsList.find((doc) => doc.type === DOC_TYPES[docStep.docType])) {
        missingDocs.push(DOC_TYPES[docStep.docType]);
      }
    }
    if (missingDocs.length > 0) {
      return setMissingDocuments(missingDocs);
    }
    return handleStep(1);
  }, [documentsList, docSteps, handleStep]);

  return (
    <>
      <div className={styles.profileDocs}>

        <h2 className="m-b-10">
          {t('onboarding.steps.company4.title')}
          &nbsp;
          {inputtedData.company?.legalName || ''}
        </h2>

        <CompanyDocumentsList
          documentsData={documentsList}
          docSteps={docSteps}
          refetchDocuments={refetchDocumentsCompany}
          companyUID={companyData?.data?.uid}
        />

        {missingDocuments?.length > 0 && (
          <div className={styles.missingBlock}>
            <Message
              className="m-t-30"
              icon={null}
              variant="alert"
              content={displayMissingDocuments}
            />
          </div>
        )}
      </div>

      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
      })}
    </>
  );
}

OnboardingTunnelCompany4.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

OnboardingTunnelCompany4.defaultProps = {
  children: null,
};

export default OnboardingTunnelCompany4;
