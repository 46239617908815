import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

// Store
import onboardingReducer from './onboardingReducer';

// Services
import { getUserProfile } from '../services/profile';
import { getCompany, getCompanyPictures } from '../services/company';
import { getMe } from '../services/identity';
import { getPersonDocuments } from '../services/person';
import { getDocumentList } from '../services/document';
import { getOnboardingProgress } from '../services/onboarding';

// Hooks
import useRoles from '../hooks/useRoles';
import { useAppContext } from './context';

// Constants
import { ONBOARDING_ROLES, ENROLMENT_STATUS } from '../utils/constants';

export const OnboardingContext = createContext();

function OnboardingProvider({ children }) {
  const { isUserTenant, isUserLessor } = useRoles();
  const { context: { me, user }, dispatch } = useAppContext();

  const {
    refetch: refetchMe,
  } = useQuery({
    queryKey: ['me-data'],
    queryFn: async () => {
      const meResponse = await getMe();

      dispatch({
        type: 'SET_ME',
        payload: meResponse.data,
      });

      return meResponse;
    },
  });

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
  } = useQuery({
    queryKey: ['user-profile-onboarding'],
    queryFn: getUserProfile,
  });

  const {
    data: documentsProfileData,
    isLoading: documentsProfileLoading,
    refetch: refetchDocumentsProfile,
  } = useQuery({
    queryKey: ['profile-documents', user],
    queryFn: () => getPersonDocuments(user?.username),
    enabled: !!user,
    keepPreviousData: true,
  });

  const initialContext = {
    step: 1,
    profileData,
    error: false,
    companyData: null,
    siretData: null,
    documentsProfileData,
    documentsCompanyData: null,
    picturesCompanyData: null,
    inputtedData: {
      profile: null,
      company: null,
    },
  };

  const [contextOnboarding, dispatchOnboarding] = useReducer(onboardingReducer, initialContext);

  const onboardingRole = useMemo(() => {
    const defaultOnboardingRole = {
      isTenant: false,
      isOwner: false,
      isOwnerWithCompany: false,
      role: '',
    };
    if (isUserTenant) {
      return {
        ...defaultOnboardingRole,
        isTenant: true,
        role: ONBOARDING_ROLES.TENANT,
      };
    }
    if (isUserLessor && contextOnboarding.inputtedData?.company?.idNumber) {
      return {
        ...defaultOnboardingRole,
        isOwnerWithCompany: true,
        role: ONBOARDING_ROLES.OWNER_WITH_COMPANY,
      };
    }
    if (isUserLessor) {
      return {
        ...defaultOnboardingRole,
        isOwner: true,
        role: ONBOARDING_ROLES.OWNER,
      };
    }
    return defaultOnboardingRole;
  }, [isUserTenant, isUserLessor, contextOnboarding.inputtedData]);

  const companyUid = useMemo(() => {
    const userCompanies = me?.aclMatrix?.companies || [];
    const { isOwnerWithCompany, isOwner } = onboardingRole;
    if (isOwnerWithCompany || isOwner) {
      return userCompanies.find((company) => company.isRealEstateAgency)?.uid || userCompanies[0]?.uid;
    }
    return null;
  }, [me, onboardingRole]);

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    refetch: refetchCompany,
  } = useQuery({
    queryKey: ['user-company-onboarding', companyUid],
    queryFn: () => getCompany(companyUid),
    enabled: !!companyUid && !onboardingRole.isTenant,
  });

  const {
    data: documentsCompanyData,
    isLoading: documentsCompanyLoading,
    refetch: refetchDocumentsCompany,
  } = useQuery({
    queryKey: ['company-documents', companyUid],
    queryFn: () => getDocumentList({
      filters: {
        companyUID: companyUid,
      },
    }),
    enabled: !!companyUid,
    keepPreviousData: true,
  });

  const {
    data: picturesCompanyData,
    isLoading: picturesCompanyLoading,
    refetch: refetchPicturesCompany,
  } = useQuery({
    queryKey: ['company-pictures', companyUid],
    queryFn: () => getCompanyPictures(companyUid),
    enabled: !!companyUid,
    keepPreviousData: true,
  });

  const {
    data: progressData,
    isLoading: isLoadingProgress,
    refetch: refetchProgress,
  } = useQuery({
    queryKey: ['onboarding-progress'],
    queryFn: getOnboardingProgress,
    enabled: !onboardingRole.isTenant,
  });

  useEffect(() => {
    if (profileData) {
      dispatchOnboarding({
        type: 'SET_PROFILE',
        payload: profileData,
      });
      if (me?.onboardingStatus !== ENROLMENT_STATUS.PENDING) {
        dispatchOnboarding({
          type: 'SET_INPUTTED_DATA',
          payload: {
            profile: profileData.data,
          },
        });
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (companyData) {
      dispatchOnboarding({
        type: 'SET_COMPANY',
        payload: companyData,
      });
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: {
          company: companyData.data,
        },
      });
    }
  }, [companyData]);

  useEffect(() => {
    if (documentsProfileData) {
      dispatchOnboarding({
        type: 'SET_DOCUMENTS_PPH',
        payload: documentsProfileData,
      });
    }
  }, [documentsProfileData]);

  useEffect(() => {
    if (documentsCompanyData) {
      dispatchOnboarding({
        type: 'SET_DOCUMENTS_COMPANY',
        payload: documentsCompanyData,
      });
    }
  }, [documentsCompanyData]);

  useEffect(() => {
    if (picturesCompanyData) {
      dispatchOnboarding({
        type: 'SET_PICTURES_COMPANY',
        payload: picturesCompanyData,
      });
    }
  }, [picturesCompanyData]);

  useEffect(() => {
    if (progressData) {
      const dataToDispatch = {};
      if (me?.onboardingStatus === ENROLMENT_STATUS.PENDING) {
        dataToDispatch.profile = progressData.data?.profile;
      }
      if (!companyUid) {
        dataToDispatch.company = progressData.data?.company;
      }
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: dataToDispatch,
      });
    }
  }, [progressData]);

  const loading = useMemo(() => (
    isLoadingProfile
    || (companyUid && (isLoadingCompany || documentsCompanyLoading || picturesCompanyLoading))
    || (!onboardingRole.isTenant && (documentsProfileLoading || isLoadingProgress))
  ), [
    isLoadingProfile,
    isLoadingCompany,
    documentsProfileLoading,
    companyUid,
    documentsCompanyLoading,
    picturesCompanyLoading,
    isLoadingProgress,
    onboardingRole,
  ]);

  const value = useMemo(() => ({
    contextOnboarding,
    dispatchOnboarding,
    loading,
    refetchProfile,
    onboardingRole,
    refetchMe,
    refetchCompany,
    refetchDocumentsProfile,
    refetchDocumentsCompany,
    refetchPicturesCompany,
    refetchProgress,
    enrolmentStatus: me?.onboardingStatus,
  }), [contextOnboarding, dispatchOnboarding, loading, refetchProfile]);

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
}

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useOnboardingContext = () => useContext(OnboardingContext);

export default OnboardingProvider;
