/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import Picto from '../Picto/Picto';

import cn from '../../../utils/cn';

import styles from './CheckboxInput.module.scss';

const CheckboxInput = ({
  name,
  id,
  checked,
  onChange,
  disabled,
  className,
  label,
  error,
}) => {
  const triggerToggle = () => {
    if (disabled) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(!checked);
    }
  };

  return (
    <>
      <div className={cn([className, styles.wrgToggleWrapper])}>
        <div
          onClick={triggerToggle}
          className={cn([
            styles.wrgToggle,
            checked ? styles.wrgToggle__checked : null,
            disabled ? styles.wrgToggle__disabled : null,
          ])}
        >
          <div className={cn([
            styles.wrgToggleContainer,
            checked ? styles.wrgToggleContainerCheck : null,
          ])}
          />
          <Picto color={checked ? 'var(--color-white)' : 'transparent'} icon="tick" width={13} height={16} className={styles.wrgToggleIcon} />
          <input
            id={id}
            aria-invalid={error != null}
            name={name}
            checked={checked}
            onChange={triggerToggle}
            type="checkbox"
            className={styles.wrgToggleInput}
          />
        </div>
        {label && (
        <label
          htmlFor={id}
          className={styles.wrgToggleLabel}
        >
            {label}
        </label>
        )}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
};

CheckboxInput.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  onChange: null,
  label: null,
  error: null,
};

export default CheckboxInput;
