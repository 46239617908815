/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

// LightBox
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';

// Components
import {
  utils,
  Button,
} from 'ui-library-unlocker';
import Image from '../../atoms/Image/Image';
import AddDocumentModal from '../AddDocumentModal/AddDocumentModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

// Services
import { deleteCompanyPicture } from '../../../services/company';

// Utils
import { showModal, hideModal } from '../../../utils/modal';

import styles from './ImageUpload.module.scss';

const UPLOAD_IMAGE_MODAL_ID = 'upload-image-modal';
const DELETE_CONFIRM_MODAL_ID = 'image-delete-confirm';

function ImageUpload({
  picture,
  className,
  entityUID,
  entityType,
  refetchPicture,
  fileType,
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [docSelected, setDocSelected] = useState(null);

  const deleteMethod = useMemo(() => {
    if (entityType === 'company') {
      return deleteCompanyPicture;
    }
    return null;
  }, [entityType]);

  const documentDeleteMutation = useMutation({
    mutationFn: () => deleteMethod(entityUID, docSelected),
    onSuccess: () => {
      refetchPicture();
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('global.documents.delete.success'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const openLightbox = useCallback(() => {
    setOpen(true);
  }, []);

  const handleImportBtn = useCallback(() => {
    showModal(UPLOAD_IMAGE_MODAL_ID);
  }, []);

  const showDeleteConfirmModal = useCallback(() => {
    setDocSelected(picture?.uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, [picture]);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleDelete = useCallback(() => {
    documentDeleteMutation.mutate(docSelected);
  }, [docSelected]);

  const thumbnail = useMemo(() => picture?.thumbnails
    ?.find((thumb) => thumb.format === 'property_thumbnail'), [picture]);

  const slides = useMemo(() => {
    if (picture) {
      return [{
        src: picture.thumbnails?.find((thumb) => thumb.format === 'property_full_screen')?.url,
        alt: 'uploaded image',
      }];
    }
    return [];
  }, [picture]);

  return (
    <>
      {picture ? (
        <div
          className={utils.cn([styles.imageUpload__img__container, className])}
          tabIndex={0}
          role="button"
          onKeyDown={null}
        >
          <Image
            className={styles.imageUpload__img}
            src={thumbnail?.url}
            alt="interieur"
          />
          <div className={styles.pictoContainer}>
            <Button
              variant="secondary"
              icon="eye"
              size="medium"
              onClick={openLightbox}
              className={styles.picto}
            />
            <Button
              variant="secondary"
              icon="trash"
              size="medium"
              onClick={showDeleteConfirmModal}
              className={styles.picto}
            />
          </div>
        </div>
      ) : (
        <Button
          variant="secondary"
          label={t('global.importFile')}
          size="medium"
          icon="import"
          onClick={handleImportBtn}
        />
      )}

      <AddDocumentModal
        modalId={UPLOAD_IMAGE_MODAL_ID}
        entityType={entityType}
        entityUID={entityUID}
        refetchDocumentList={refetchPicture}
        fileType={fileType}
        fileFormat="image"
        documentType={`${entityType}Picture`}
      />

      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleDelete}
        onCancel={cancelDelete}
        loading={documentDeleteMutation.isLoading}
      />

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Thumbnails, Counter, Zoom, Download]}
        slides={slides}
        carousel={{
          finite: true,
        }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
        controller={{
          closeOnBackdropClick: true,
        }}
      />
    </>
  );
}

ImageUpload.propTypes = {
  picture: PropTypes.shape({
    thumbnails: PropTypes.arrayOf(PropTypes.shape({
      format: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    uid: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  entityUID: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(['company', 'person']).isRequired,
  refetchPicture: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

ImageUpload.defaultProps = {
  picture: null,
  className: '',
};

export default ImageUpload;
