import * as yup from 'yup';

export const documentInitialValues = {
  filename: '',
  userUid: null,
  metadata: null,
};

export const documentSchema = yup.object().shape({
  // customName: yup.string().max(50, 'global.form.errors.fileNameTooLong').required('global.form.errors.required'),
  filename: yup.string(),
  userUid: yup.string().required('global.form.errors.required'),
  metadata: yup.object().shape(),
});
