/* eslint-disable max-len */
import { useMemo } from 'react';

import { useAppContext } from '../store/context';

import { ENROLMENT_STATUS } from '../utils/constants';

const COMPLETION_LEVELS = {
  1: 1,
  2: 2,
  3: 3,
};

const useProfileCompletion = () => {
  const { context: { me } } = useAppContext();

  /*
    1, 2, 3. Represents the level of completion of the profile
    1: Profile is not completed
    2: Profile is being reviewed
    3: Profile is validated by Ezyness
    */
  const profileCompletionLevel = useMemo(() => {
    switch (me?.onboardingStatus) {
      case ENROLMENT_STATUS.PENDING:
      case ENROLMENT_STATUS.PROFILE_DONE:
        return COMPLETION_LEVELS[1];

      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
        return COMPLETION_LEVELS[2];

      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return COMPLETION_LEVELS[3];

      default:
        return COMPLETION_LEVELS[1];
    }
  }, [me?.onboardingStatus]);

  const isUserCompletionLevel1 = useMemo(() => profileCompletionLevel === COMPLETION_LEVELS[1], [profileCompletionLevel]);
  const isUserCompletionLevel2 = useMemo(() => profileCompletionLevel === COMPLETION_LEVELS[2], [profileCompletionLevel]);
  const isUserCompletionLevel3 = useMemo(() => profileCompletionLevel === COMPLETION_LEVELS[3], [profileCompletionLevel]);

  /*
    see ENROLMENT_STATUS
  */
  const onboardingStatus = useMemo(() => me?.onboardingStatus || null, [me]);

  return {
    profileCompletionLevel,
    onboardingStatus,
    isUserCompletionLevel1,
    isUserCompletionLevel2,
    isUserCompletionLevel3,
  };
};

export default useProfileCompletion;
