import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

// Components
import {
  Button,
  utils,
} from 'ui-library-unlocker';
import FileInput from '../../molecules/FileInput/FileInput';
import Modal from '../../molecules/Modal/Modal';

// Utils
import { hideModal } from '../../../utils/modal';
import { documentSchema, documentInitialValues } from '../../../utils/forms/documentSchema';
import { handleFileSelect } from '../../../utils/documents';

// Hooks
import useFileUpload from '../../../hooks/useFileUpload';
import { useAppContext } from '../../../store/context';

// Services
import { createCompanyDocument, createCompanyPicture } from '../../../services/company';
import { createUserDocument } from '../../../services/person';

import styles from './AddDocumentModal.module.scss';

function AddDocumentModal({
  modalId,
  entityUID,
  entityType,
  refetchDocumentList,
  fileType,
  onClose,
  fileFormat,
  documentType,
}) {
  const { t } = useTranslation();
  const { context: { user } } = useAppContext();

  const [isHugeDocument, setHugeDocument] = useState(false);

  const initialValues = useMemo(() => {
    if (user) {
      return {
        ...documentInitialValues,
        userUid: user?.username,
      };
    }
    return documentInitialValues;
  }, [user]);

  const resetDocumentModal = () => {
    hideModal(modalId);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const createDocumentMethod = useMemo(() => {
    if (entityType === 'company' && fileFormat === 'image') {
      return createCompanyPicture;
    }
    if (entityType === 'company') {
      return createCompanyDocument;
    }
    return createUserDocument;
  }, [entityType]);

  const createDocumentCb = useCallback(async (documentUid, fileName) => {
    const createdDocument = await createDocumentMethod({
      type: fileType,
      name: fileName,
      documentUid,
      [`${entityType}UID`]: entityUID,
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  }, [entityType, entityUID, fileType, refetchDocumentList, resetDocumentModal, createDocumentMethod]);

  const [{ uploadFile, setFile }, {
    file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const formik = useFormik({
    initialValues,
    validationSchema: documentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'document_added',
            entityType,
            fileType,
          },
        });
        uploadFile({
          ...values,
          type: documentType,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    resetDocumentModal();
  }, [onClose, resetDocumentModal]);

  const handleFileChange = useCallback((e) => {
    handleFileSelect(e, setHugeDocument, formik, setFile, t);
  }, [formik, setFile, t]);

  const acceptedFormats = useMemo(() => {
    let accepted;
    if (fileFormat === 'image') {
      accepted = [
        'image/jpg',
        'image/png',
        'image/jpeg',
      ];
    } else {
      accepted = [
        'image/jpg',
        'image/png',
        'image/jpeg',
        'application/pdf',
      ];
    }
    return accepted.join(',');
  }, [fileFormat]);

  return (
    <Modal
      className={styles.modal}
      id={modalId}
      title={isHugeDocument
        ? t('global.documents.addDocumentForm.errors.size.title')
        : t(`global.documents.addDocumentForm.titles.${fileType}`)}
      size="large"
      onClose={handleClose}
    >
      {!isHugeDocument ? (
        <form onSubmit={formik.handleSubmit} className={styles.addForm}>
          <FileInput
            className="m-t-25"
            id="document-file"
            name="document-file"
            label={t('global.documents.addDocumentForm.fields.file')}
            help={t(`global.documents.addDocumentForm.${fileFormat === 'image' ? 'imgFileHelp' : 'fileHelp'}`)}
            accept={acceptedFormats}
            value={file}
            onChange={handleFileChange}
          />
          <div className={styles.submitBtn}>
            <Button
              type="submit"
              loading={isUploading}
              className="m-t-25"
              label={t('global.documents.addDocumentForm.submit')}
            />
          </div>
        </form>
      ) : (
        <div className={styles.hugeDocument}>
          <p className={styles.errorModalSubtitle}>{t('global.documents.addDocumentForm.errors.size.message')}</p>
          <div className={styles.submitBtn}>
            <Button
              className="m-t-25"
              label={t('global.documents.addDocumentForm.errors.size.action')}
              onClick={() => {
                formik.resetForm();
                setFile(null);
                setHugeDocument(false);
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

AddDocumentModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  entityUID: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(['company', 'person']).isRequired,
  refetchDocumentList: PropTypes.func,
  fileType: PropTypes.string,
  onClose: PropTypes.func,
  fileFormat: PropTypes.oneOf(['image', 'any']),
  documentType: PropTypes.string,
};

AddDocumentModal.defaultProps = {
  refetchDocumentList: () => {},
  fileType: null,
  onClose: () => {},
  fileFormat: 'any',
  documentType: 'userDocuments',
};

export default AddDocumentModal;
