// Atoms
import Button from './components/atoms/Button/Button';
import Picto from './components/atoms/Picto/Picto';
import TextInput from './components/atoms/TextInput/TextInput';
import DateInput from './components/atoms/DateInput/DateInput';
import SelectInput from './components/atoms/SelectInput/SelectInput';
import ToggleInput from './components/atoms/ToggleInput/ToggleInput';
import WysiwygInput from './components/atoms/WysiwygInput/WysiwygInput';
import Message from './components/atoms/Message/Message';
import Tag from './components/atoms/Tag/Tag';
import CheckboxInput from './components/atoms/CheckboxInput/CheckboxInput';
import RadioInput from './components/atoms/RadioInput/RadioInput';
import PhoneInput from './components/atoms/PhoneInput/PhoneInput';
import TextAreaInput from './components/atoms/TextAreaInput/TextAreaInput';
import MessageInput from './components/atoms/MessageInput/MessageInput';
import Thumbnail from './components/atoms/Thumbnail/Thumbnail';
import CheckedItem from './components/atoms/CheckedItem/CheckedItem';
import UnlockerLoader from './components/atoms/UnlockerLoader/UnlockerLoader';
import ProgressBar from './components/atoms/ProgressBar/ProgressBar';

// Molecules
import Toast from './components/molecules/Toast/Toast';
import Pagination from './components/molecules/Pagination/Pagination';
import CheckboxGroup from './components/molecules/CheckboxGroup/CheckboxGroup';
import RadioGroup from './components/molecules/RadioGroup/RadioGroup';
import ToggleGroup from './components/molecules/ToggleGroup/ToggleGroup';
import CheckList from './components/molecules/CheckList/CheckList';
import ToggleButton from './components/molecules/ToggleButton/ToggleButton';

// Organisms
import Table from './components/organisms/Table/Table';
import CompletionStep from './components/organisms/CompletionStep/CompletionStep';

// Templates
import WithHeader from './components/templates/WithHeader/WithHeader';
import CompletionDashboard from './components/templates/CompletionDashboard/CompletionDashboard';

// Hooks

// Utils
import cn from './utils/cn';
import { toast } from './utils/toast';
import { getCompletePhoneNumber } from './utils/phone';
import { euroToCents, centsToEuro } from './utils/money';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/index.scss';

const utils = {
  cn,
  toast,
  getCompletePhoneNumber,
  euroToCents,
  centsToEuro,
};

export {
  // Atoms
  Button,
  Picto,
  TextInput,
  DateInput,
  SelectInput,
  ToggleInput,
  WysiwygInput,
  Message,
  Tag,
  CheckboxInput,
  RadioInput,
  PhoneInput,
  TextAreaInput,
  MessageInput,
  Thumbnail,
  CheckedItem,
  UnlockerLoader,
  ProgressBar,

  // Molecules
  Toast,
  Pagination,
  CheckboxGroup,
  RadioGroup,
  ToggleGroup,
  CheckList,
  ToggleButton,

  // Organisms
  Table,
  CompletionStep,

  // Templates
  WithHeader,
  CompletionDashboard,

  // Utils
  utils,
};
