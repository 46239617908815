import api from './_client';
import { ENROLMENT_STATUS } from '../utils/constants';

const {
  PROFILE_DONE,
  COMPLETED,
  ENROLMENT_BYPASSED,
  PENDING,
  NO_PROFILE,
} = ENROLMENT_STATUS;

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @returns {Promise}
 */
export const getOwners = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[onboardingStatus][--in--]': `${PROFILE_DONE},${COMPLETED},${ENROLMENT_BYPASSED},${PENDING},${NO_PROFILE}`,
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/person/property-owners', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const searchOwner = (data) => api.post('/person/property-owners/search', data);

export const getOwner = (uid) => api.get(`/person/property-owners/${uid}`);

export const createOwner = (data) => api.post('/person/property-owners', data);

export const updateOwner = (uid, data) => api.put(`/person/property-owners/${uid}`, data);
