import {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';
// import AWS from 'aws-sdk';
import { utils } from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../store/context';
import useFileUpload from './useFileUpload';

// Services
import {
  getConversations,
  getConversation,
  getMessagesFromConversation,
  hitMessage,
  sendUserMessage,
} from '../services/messages';

// Config AWS disabled for now
// AWS.config.update({
//   region: process.env.REACT_APP_AWS_REGION,
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
// });

const useConversation = (conversationUid) => {
  const { t } = useTranslation();
  const { context: { uiBuilders, user }, refetchMe } = useAppContext();
  const [{ uploadFiles }] = useFileUpload();

  const [sentMessages, setSentMessages] = useState([]);

  const { refetch: refetchConversations } = useQuery({
    queryKey: ['conversation-list'],
    queryFn: () => getConversations({
      page: 1,
      itemsPerPage: 100,
    }),
    keepPreviousData: true,
    enabled: false,
  });

  const {
    data: conversationData,
    isFetching: isFetchingConversation,
  } = useQuery({
    queryKey: ['conversation-data', conversationUid],
    queryFn: async () => {
      if (conversationUid) {
        const conversationResponse = await getConversation(conversationUid);
        return conversationResponse;
      }
      return null;
    },
    keepPreviousData: true,
  });

  const {
    data: conversationMessages,
    isFetching: isFetchingMessages,
  } = useQuery({
    queryKey: ['conversation-messages', conversationData],
    queryFn: () => (conversationData?.data ? getMessagesFromConversation(conversationData.data.uid, {
      page: 1,
      // handle loading on scroll
      itemsPerPage: 100,
    }) : null),
    keepPreviousData: true,
    onSuccess: () => {
      setSentMessages([]);
    },
  });

  const authors = useMemo(() => {
    if (conversationData?.data) {
      const recipient = conversationData.data.recipient || {
        firstName: t('messages.admin.firstName'),
        lastName: t('messages.admin.lastName'),
        properties: [],
        uid: 'admin',
      };
      return [conversationData.data.sender, recipient];
    }
    return [];
  }, [conversationData?.data]);

  const subject = useMemo(() => {
    if (conversationData?.data?.subject && uiBuilders?.['/messaging/ui']) {
      const { conversationSubject } = uiBuilders['/messaging/ui'];
      const found = Object.keys(conversationSubject).find((key) => key === conversationData?.data?.subject);
      if (found) return conversationSubject[found];
    }
    return null;
  }, [conversationData?.data, uiBuilders]);

  const address = useMemo(() => {
    if (conversationData?.data?.property?.address) {
      const {
        street,
        streetNumber,
        zipCode,
        city,
      } = conversationData.data.property.address;

      return `${streetNumber || ''} ${street}, ${zipCode} ${city}`;
    }
    return null;
  }, [conversationData?.data]);

  const date = useMemo(() => {
    if (conversationData?.data) {
      const { createdAt: { date: convDate } } = conversationData.data;
      return format(new Date(convDate), 'dd MMM. yyyy', { locale: fr });
    }
    return null;
  }, [conversationData?.data]);

  // Mark messages as read
  useEffect(() => {
    if (conversationMessages?.data?.collection && user?.username) {
      const unreadMessages = conversationMessages.data.collection
        .filter((message) => (message.authorUid !== user.username) && !message.hit);

      Promise.all(unreadMessages.map((message) => hitMessage(message.uid))).then(() => {
        refetchConversations();
        refetchMe();
      });
    }
  }, [conversationMessages, user]);

  const sendMessage = async ({ content, files }) => {
    try {
      let documentUids = [];
      if (files?.length > 0) {
        documentUids = await uploadFiles(files, null);
      }

      const userMessage = await sendUserMessage(conversationData?.data?.uid, {
        createdAt: (new Date().getTime() / 1000).toFixed(0).toString(),
        content,
        documentUids,
      });

      setSentMessages([...sentMessages, {
        authorUid: user?.username,
        content,
        createdAt: {
          date: format(new Date(), 'yyyy-MM-dd HH:mm:ss.000000'),
          timezone_type: 3,
          timezone: 'UTC',
        },
        // INFO: The local message will not contain the attachments since they are not yet available
        documents: files.map((file, index) => ({
          uid: documentUids[index],
          fileName: file?.file?.name,
        })),
        hit: false,
        uid: '',
      }]);

      utils.toast.success(t('messages.success.messageSent'));

      return userMessage?.data;
    } catch (error) {
      utils.toast.error(t('messages.errors.messageNotSent'));
      throw new Error(error);
    }
  };

  const isConversationLoading = useMemo(() => {
    if (!conversationData?.data) return true;
    if (!conversationMessages?.data?.collection) return true;
    if (!authors.length) return true;
    if (!subject) return true;
    if (!date) return true;
    return false;
  }, [conversationData?.data, conversationMessages?.data?.collection, authors, subject, date, address]);

  const isFetching = useMemo(() => {
    if (isFetchingConversation || isFetchingMessages) return true;
    return false;
  }, [isFetchingConversation, isFetchingMessages]);

  const messages = useMemo(() => {
    if (!conversationMessages?.data?.collection) return null;
    const reversed = conversationMessages?.data?.collection?.slice(0).reverse();
    return [...reversed, ...sentMessages];
  }, [conversationMessages?.data?.collection, sentMessages]);

  return {
    conversation: conversationData?.data,
    messages,
    authors,
    subject,
    date,
    address,
    sendMessage,
    isConversationLoading,
    isFetching,
  };
};

export default useConversation;
