import api from './_client';
import { ENROLMENT_STATUS } from '../utils/constants';

const {
  PROFILE_DONE,
  COMPLETED,
  ENROLMENT_BYPASSED,
  PENDING,
  NO_PROFILE,
} = ENROLMENT_STATUS;

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getTenants = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': filters?.status?.join?.(','),
    'filters[onboardingStatus][--in--]': `${PROFILE_DONE},${COMPLETED},${ENROLMENT_BYPASSED},${PENDING},${NO_PROFILE}`,
    q: filters.search?.[0],
  };
  return api.get('/person/tenants', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getTenantsByUid = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[status][--in--]': filters?.status?.join?.(','),
    'filters[onboardingStatus][--in--]': `${PROFILE_DONE},${COMPLETED},${ENROLMENT_BYPASSED},${PENDING},${NO_PROFILE}`,
    'filters[uid][--in--]': filters.uid,
    q: filters.search?.[0],
  };
  return api.get('/person/tenants', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getTenantsNoFilter = () => api.get('/person/tenants');

export const getTenant = (uid) => api.get(`/person/tenants/${uid}`);

export const createTenant = (data) => api.post('/person/tenants', data);

export const updateTenant = (uid, data) => api.put(`/person/tenants/${uid}`, data);

export const createTenantDocument = ({
  type,
  name,
  documentUid,
  personUid,
}) => api.post(`/person/tenants/${personUid}/documents`, {
  type, name, documentUid, personUid,
});
