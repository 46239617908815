import { ENROLMENT_STATUS } from '../../../utils/constants';

const formatProfileCompletion = ({
  completionPercentage,
  personnalInformations,
  documents,
  enrolmentComplete,
  onboardingStatus,
  t,
  navigate,
  isUserCompletionLevel2,
  isUserCompletionLevel3,
  showModal,
  COMPLETION_LIST_WIDTH,
}) => {
  const getValidationTagValue = () => {
    const status = [
      ENROLMENT_STATUS.ENROLMENT_REQUESTED,
      ENROLMENT_STATUS.ENROLMENT_PENDING,
    ].includes(onboardingStatus);

    return ({
      completion: t(`dashboard.completion.${status ? 'waiting' : 'invalid'}`),
    });
  };

  const generateDocSteps = (docs = {}) => Object.entries(docs ?? {}).map(([key, value]) => ({
    id: key,
    label: t(`dashboard.completion.profile.step3.docTypes.${key}`),
    completion: t(`dashboard.completion.${value ? 'finished' : 'waiting'}`),
    completed: value,
  }));

  const getDocsPercent = (docs = {}) => {
    docs = Object.values(docs ?? {});
    const { length } = docs;
    if (length <= 0) return 0;
    const docsCompleted = docs.reduce((acc, value) => acc + (value ? 1 : 0), 0);
    const percent = Math.floor((docsCompleted / length) * 100);
    return percent;
  };

  const getEnrolmentPercent = () => {
    if (!enrolmentComplete) {
      if (isUserCompletionLevel2) return 80;
      return 0;
    }
    return 100;
  };

  return (
    {
      id: 1,
      title: t('dashboard.completion.profile.title'),
      progression: completionPercentage,
      toggleLabel: t('dashboard.completion.details'),
      subtitle: t('dashboard.completion.profile.subtitle', { count: 1 }),
      width: COMPLETION_LIST_WIDTH,
      disabled: completionPercentage === 100,
      items: [
        {
          id: 1,
          label: t('dashboard.completion.profile.step1.label'),
          checked: !!personnalInformations,
          progression: personnalInformations ? 100 : 0,
          onClick: () => navigate('/profile'),
        },
        {
          id: 2,
          label: t('dashboard.completion.profile.step3.label'),
          checked: documents && Object.values(documents).length > 0 && !!Object.values(documents).every((doc) => doc),
          progression: getDocsPercent(documents),
          onClick: () => navigate({ pathname: '/profile', hash: 'documents' }),
          steps: generateDocSteps(documents),
        },
        {
          id: 3,
          label: t('dashboard.completion.profile.step4.label'),
          checked: !!enrolmentComplete,
          progression: getEnrolmentPercent(),
          onClick: () => showModal('document-validation-completion-modal'),
          steps: !enrolmentComplete && isUserCompletionLevel2 ? [
            {
              id: 1,
              label: t('dashboard.completion.profile.step4.level1'),
              ...getValidationTagValue(),
              completed: isUserCompletionLevel3,
            },
          ] : [],
        },
      ],
    }
  );
};

export {
  formatProfileCompletion,
};
