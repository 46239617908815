import { useTranslation } from 'react-i18next';

import styles from './DocsRequirements.module.scss';

function DocsRequirements() {
  const { t } = useTranslation();
  return (
    <div className={styles.docsRequirements}>
      <ul>
        <li>{t('global.docStep.requirements.required')}</li>
        <li>{t('global.docStep.requirements.check')}</li>
        <li>{t('global.docStep.requirements.upload')}</li>
      </ul>
    </div>
  );
}

DocsRequirements.propTypes = {
};

export default DocsRequirements;
