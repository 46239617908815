import api from './_client';

export const checkExistingSIRET = (siret) => api.get(`/person/onboarding_funnel/check-siret/${siret}`);

export const getOnboardingProgress = () => api.get('/person/onboarding_funnel/get');

export const updateOnboardingProgress = (step, data) => api.put(`/person/onboarding_funnel/update/${step}`, data);

export const completeOnboardingFunnel = () => api.post('/identity-access/user/set_onboarding_funnel_status', {
  hasCompletedOnboardingFunnel: true,
});
